import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAddVesselArticleMutation } from "@/app/api/slices/manager/managerKnowledgeBaseApiSlice";
import { WhiteBox } from "@/components/WhiteBox";
import { type FormType } from "@/components/forms/KnowledgeBaseAdminArticleUpsertForm/formSchema";
import { KnowledgeBaseManagerArticleUpsertForm } from "@/components/forms/KnowledgeBaseManagerArticleUpsertForm";
import { useToast } from "@/components/ui/use-toast";
import { useJoyride } from "@/contexts/JoyrideContext";
import { joyride_redirect_7_index } from "@/hocs/JoyrideWrapper";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const ManagerAddArticlePage = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const vesselId = useCurrentVesselId();

	const [addArticleMutation, { isLoading: isAddingArticle }] = useAddVesselArticleMutation();

	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		if (!run && stepIndex === joyride_redirect_7_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, []);

	const handleAddArticle = async (data: FormType) => {
		await addArticleMutation({ vesselId, data })
			.unwrap()
			.then(() =>
				navigate("/knowledge-base", {
					state: {
						toast: {
							variant: "success",
							title: "Successfully added article.",
						},
					},
				}),
			)
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<WhiteBox id="add-article-box">
			<h1 className="mb-4 text-xl font-semibold md:text-2xl">Add Article</h1>
			<KnowledgeBaseManagerArticleUpsertForm
				onSubmit={handleAddArticle}
				isLoading={isAddingArticle}
			/>
		</WhiteBox>
	);
};
