import { type Table } from "@tanstack/react-table";

import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { AddCrewMemberDialog } from "@/components/dialogs/AddCrewMemberDialog";
import { ImportCrewMemberDialog } from "@/components/dialogs/ImportCrewMemberDialog";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { type User } from "@/types/User";
import { useEffect, useState } from "react";

type Props = {
	table?: Table<User>;
} & (
	| { data: User[]; setVisibleData: (data: User[]) => void }
	| { data?: never; setVisibleData?: never }
);

export const CrewMembersDataControls = ({ table, data, setVisibleData }: Props) => {
	const vesselId = useCurrentVesselId();

	const { data: departmentsResponse, isLoading } = useGetDepartmentsAndPositionsQuery({ vesselId });

	return (
		<div className="mb-4 flex flex-wrap items-start justify-between gap-x-4 gap-y-2">
			<AddCrewMemberDialog />
			<ImportCrewMemberDialog />
			<div className="flex shrink grow flex-wrap-reverse justify-start gap-2 sm:justify-end">
				<div
					id="crew-member-listing-filters"
					className="flex flex-wrap justify-start gap-2 sm:justify-end lg:flex-nowrap"
				>
					{table && (
						<TableDepartmentsFilter
							isLoading={isLoading}
							departments={departmentsResponse?.data || []}
							table={table}
						/>
					)}
					{table && <TableOnBoardFilter table={table} />}
					{table && <TableSearch table={table} />}
					{data && (
						<GridFilters
							data={data}
							setVisibleData={setVisibleData}
							isLoading={isLoading}
							departments={departmentsResponse?.data || []}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const TableDepartmentsFilter = ({
	isLoading,
	departments,
	table,
}: {
	isLoading: boolean;
	departments: { id: number; name: string }[];
	table: Table<User>;
}) => {
	if (isLoading) return <Skeleton className="h-10 w-60" />;

	return (
		<Select
			onValueChange={(e) => {
				if (e === "all") return table.getColumn("department")?.setFilterValue(undefined);
				return table.getColumn("department")?.setFilterValue(e);
			}}
			// defaultValue="all"
		>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Department filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					{departments.map((department) => {
						return (
							<SelectItem value={department.name} key={department.name}>
								{department.name}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};

const TableOnBoardFilter = ({ table }: { table: Table<User> }) => {
	return (
		<Select
			onValueChange={(e) => {
				if (e === "all") return table.getColumn("isOnboard")?.setFilterValue(undefined);
				return table.getColumn("isOnboard")?.setFilterValue(e === "yes" ? true : false);
			}}
			// defaultValue="all"
		>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Onboard filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					<SelectItem value="yes">Yes</SelectItem>
					<SelectItem value="no">No</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};

const TableSearch = ({ table }: { table: Table<User> }) => {
	return (
		<Input
			placeholder="Search..."
			value={table.getState().globalFilter ?? ""}
			onChange={(event) => table.setGlobalFilter(event.target.value)}
			className="max-w-sm"
		/>
	);
};

const GridFilters = ({
	data,
	setVisibleData,
	isLoading,
	departments,
}: {
	data: User[];
	setVisibleData: (data: User[]) => void;
	isLoading: boolean;
	departments: { id: number; name: string }[];
}) => {
	const [departmentsFilterValue, setDepartmentsFilterValue] = useState<string | undefined>(
		undefined,
	);
	const [onBoardFilterValue, setOnBoardFilterValue] = useState<string | undefined>(undefined);
	const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

	useEffect(() => {
		const shouldFilterByDepartment =
			departmentsFilterValue !== "all" && departmentsFilterValue !== undefined;
		const shouldFilterByOnBoard = onBoardFilterValue !== "all" && onBoardFilterValue !== undefined;
		const shouldFilterBySearch = searchValue !== "" && searchValue !== undefined;

		if (!shouldFilterByDepartment && !shouldFilterByOnBoard && !shouldFilterBySearch)
			return setVisibleData(data);

		const filteredData = data.filter((crewMember) => {
			if (shouldFilterByDepartment && crewMember?.department?.name !== departmentsFilterValue)
				return false;

			if (shouldFilterByOnBoard && crewMember.isOnboard !== (onBoardFilterValue === "yes"))
				return false;

			if (
				shouldFilterBySearch &&
				!crewMember.firstName.toLowerCase().includes(searchValue.toLowerCase()) &&
				!crewMember.lastName.toLowerCase().includes(searchValue.toLowerCase()) &&
				!crewMember.phone.toLowerCase().includes(searchValue.toLowerCase()) &&
				!crewMember.email.toLowerCase().includes(searchValue.toLowerCase())
			)
				return false;

			return true;
		});

		setVisibleData(filteredData);
	}, [departmentsFilterValue, onBoardFilterValue, searchValue, data, setVisibleData]);

	return (
		<>
			<GridDepartmentsFilter
				value={departmentsFilterValue}
				onValueChange={setDepartmentsFilterValue}
				isLoading={isLoading}
				departments={departments}
			/>
			<GridOnBoardFilter value={onBoardFilterValue} onValueChange={setOnBoardFilterValue} />
			<GridSearch value={searchValue} onValueChange={setSearchValue} />
		</>
	);
};

const GridDepartmentsFilter = ({
	value,
	onValueChange,
	isLoading,
	departments,
}: {
	value: string | undefined;
	onValueChange: (value: string) => void;
	isLoading: boolean;
	departments: { id: number; name: string }[];
}) => {
	if (isLoading) return <Skeleton className="h-10 w-60" />;

	return (
		<Select value={value} onValueChange={onValueChange}>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Department filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					{departments.map((department) => {
						return (
							<SelectItem value={department.name} key={department.name}>
								{department.name}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};

const GridOnBoardFilter = ({
	value,
	onValueChange,
}: {
	value: string | undefined;
	onValueChange: (value: string) => void;
}) => {
	return (
		<Select value={value} onValueChange={onValueChange}>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Onboard filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					<SelectItem value="yes">Yes</SelectItem>
					<SelectItem value="no">No</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};

const GridSearch = ({
	value,
	onValueChange,
}: {
	value: string | undefined;
	onValueChange: (value: string) => void;
}) => {
	return (
		<Input
			placeholder="Search..."
			value={value}
			onChange={(e) => onValueChange(e.target.value)}
			className="max-w-sm"
		/>
	);
};
