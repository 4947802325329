import { useState } from "react";
import { useParams } from "react-router-dom";

import { useAddCrewMemberDocumentMutation } from "@/app/api/slices/documentsApiSlice";
import { CrewMemberDocumentsAddForm } from "@/components/forms/CrewMemberDocumentsAddForm";
import { type FormType } from "@/components/forms/CrewMemberDocumentsAddForm/fromSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const AddCrewMemberDocumentsDialog = () => {
	const params = useParams();
	const vesselId = useCurrentVesselId();

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addCrewMemberDocumentsMutation, { isLoading }] = useAddCrewMemberDocumentMutation();

	const handleAddCrewMemberDocuments = async (data: FormType) => {
		const formData = new FormData();

		for (const item in data) {
			// @ts-expect-error to-do: fix this
			const value = data[item];
			if (value instanceof FileList || value instanceof Array) {
				Array.from(value).forEach((file, index) => {
					formData.append(`${item}[${index}]`, file);
				});
			} else {
				formData.append(item, value);
			}
		}

		await addCrewMemberDocumentsMutation({ vesselId, userId: params.userId, data: formData })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added document.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button id="add-documents-button" className="uppercase">
					Add documents
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px] md:max-w-[750px]">
				<DialogHeader>
					<DialogTitle>Add Documents</DialogTitle>
				</DialogHeader>
				<CrewMemberDocumentsAddForm onSubmit={handleAddCrewMemberDocuments} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};
