import { useState } from "react";
// import { useAuth } from "@/hooks/useAuth";
// import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { Calendar } from "@/components/ship-calendar/Calendar";
import { Controls } from "@/components/ship-calendar/Controls";


export const ShipCalendar = () => {
	// const auth = useAuth();
	// const vessel_id = useCurrentVesselId();
	const [selectedCrewMemberId, setSelectedCrewMemberId] = useState<number | string | undefined>();


	return (
		<div className="flex flex-col lg:flex-row">
			<Calendar 
				selectedCrewMemberId={selectedCrewMemberId}
				setSelectedCrewMemberId={setSelectedCrewMemberId}
			
			/>

			<section className="hidden lg:block">
				<Controls 
					selectedCrewMemberId={selectedCrewMemberId}
				/>
			</section>
		</div>
	);
};
