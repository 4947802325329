import { Pen } from "lucide-react";
import { useState } from "react";

import { useUpdateVesselMutation } from "@/app/api/slices/admin/vesselsApiSlice";
import { VesselUpsertForm } from "@/components/forms/VesselUpsertForm";
import { type FormType } from "@/components/forms/VesselUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { type Vessel } from "@/types/Vessel";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	vesselId: number;
	vessel: Vessel;
};

export const EditVesselDialog = ({ vesselId, vessel }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateVesselMutation, { isLoading }] = useUpdateVesselMutation();

	const handleUpdateVessel = async (data: FormType) => {
		await updateVesselMutation({ data, vesselId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated vessel.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#2055A3" }}>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Vessel</DialogTitle>
				</DialogHeader>
				<VesselUpsertForm
					onSubmit={handleUpdateVessel}
					isLoading={isLoading}
					defaultValues={{ ...vessel, fleet: vessel.fleet?.id }}
				/>
			</DialogContent>
		</Dialog>
	);
};
