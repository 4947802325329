import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
    DialogDescription,
    DialogClose
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon, AlertCircleIcon } from "lucide-react";
import { isRotationLengthMirrored } from "@/components/calendar/utils";
import { ButtonLoader } from "@/components/ButtonLoader";
import { useDateFormatter } from "@/hooks/useDateFormatter";

type Props = {
	showChangeDayModal: boolean,
    showChangeDayModalHandler: () => void,
    startDate: string | undefined,
    endDate: string | undefined,
    rotation: undefined | null | string,
    applyToAllHandler: (checked: boolean) => void,
    applyToAll: boolean,
    isLoadingEditChangeDay: boolean,
    handleShiftRotations: () => Promise<void>
};

export const AdaptModal = ({
	showChangeDayModal,
    showChangeDayModalHandler,
    startDate,
    endDate,
    rotation,
    applyToAllHandler,
    applyToAll,
    isLoadingEditChangeDay,
    handleShiftRotations,
}: Props) => {
    const { format } = useDateFormatter();

	return (
		<Dialog open={showChangeDayModal} onOpenChange={showChangeDayModalHandler} defaultOpen={false}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>Are you absolutely sure?</DialogTitle>
                    <DialogDescription>
                        This action will edit change day from <b>{startDate ? format(startDate) : "-"}</b> to <b>{endDate ? format(endDate) : "-"}</b>.
                    </DialogDescription>
                </DialogHeader>


                <div className={cn(`mt-2 flex items-center`, isRotationLengthMirrored(rotation) ? "" : "hidden")} >
                    <div className="me-2">
                        <Checkbox.Root 
                            className="flex h-7 w-7 items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
                            onCheckedChange={applyToAllHandler}
                            checked={applyToAll}
                            disabled={isLoadingEditChangeDay}
                        >
                            <Checkbox.Indicator className="CheckboxIndicator">
                                <CheckIcon className="w-5 h-5 text-rose-600" />
                            </Checkbox.Indicator>
                        </Checkbox.Root>
                    </div>

                    <label className="text-sm text-rose-600 font-semibold flex items-center">
                        <AlertCircleIcon className="me-1"/>
                        Adapt calendar to the manual change
                    </label>
                </div>
                
                
                <div className="flex justify-between">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button onClick={handleShiftRotations} disabled={isLoadingEditChangeDay}>
                        <ButtonLoader isLoading={isLoadingEditChangeDay} />
                        Continue
                    </Button>
                    </div>

            </DialogContent>
        </Dialog>
	);
};
