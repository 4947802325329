import { type Table } from "@tanstack/react-table";

import { useGetFleetsQuery } from "@/app/api/slices/admin/adminFleetApiSlice";
import { AddVesselDialog } from "@/components/dialogs/AddVesselDialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { type Vessel } from "@/types/Vessel";

type Props = {
	table?: Table<Vessel>;
} & (
	| { data: Vessel[]; setVisibleData: (data: Vessel[]) => void }
	| { data?: never; setVisibleData?: never }
);

export const VesselsTableDataControls = ({ table }: Props) => {
	const { data: fleetsResponse, isLoading } = useGetFleetsQuery({});

	return (
		<div className="mb-4 flex flex-wrap items-start justify-between gap-x-4 gap-y-2">
			<AddVesselDialog />
			<div className="flex shrink grow flex-wrap-reverse justify-start gap-2 sm:justify-end">
				<div
					id="crew-member-listing-filters"
					className="flex flex-wrap justify-start gap-2 sm:justify-end lg:flex-nowrap"
				>
					{table && (
						<TableFleetFilter
							isLoading={isLoading}
							fleets={fleetsResponse?.data || []}
							table={table}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const TableFleetFilter = ({
	isLoading,
	fleets,
	table,
}: {
	isLoading: boolean;
	fleets: { id: number; name: string }[];
	table: Table<Vessel>;
}) => {
	if (isLoading) return <Skeleton className="h-10 w-60" />;

	return (
		<Select
			onValueChange={(e) => {
				if (e === "all") return table.getColumn("fleet")?.setFilterValue(undefined);
				return table.getColumn("fleet")?.setFilterValue(e);
			}}
		>
			<SelectTrigger className="w-60">
				<SelectValue placeholder="Fleet filter" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem value="all">All</SelectItem>
					{fleets.map((fleet) => {
						return (
							<SelectItem key={fleet.name} value={fleet.name}>
								{fleet.name}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
