import { ChevronsUpDown } from "lucide-react";

import { DepartmentStatistics } from "@/components/DepartmentStatistics";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { useJoyride } from "@/contexts/JoyrideContext";
import { type StringOpenStates } from "@/types/AccordionOpenStates";

type Props = {
	openStates: StringOpenStates;
	toggleOpenState: (sectionId: string) => void;
	departments: {
		id: number;
		name: string;
		positionsStats: { name: string; onboard: number; offboard: number }[];
	}[];
};

export const VesselDepartmentsStatisticsAccordion = ({
	openStates,
	toggleOpenState,
	departments,
}: Props) => {
	const { setStepIndex } = useJoyride();

	return (
		<div className="flex flex-col gap-3">
			{departments.map((department, index) => {
				return (
					<Collapsible
						key={department.id}
						open={openStates[`dep-${index}`] || false}
						onOpenChange={() => toggleOpenState(`dep-${index}`)}
						className="space-y-2"
					>
						<div className="flex items-center justify-between space-x-4">
							<CollapsibleTrigger asChild>
								<Button
									onClick={() => setStepIndex(11)}
									variant="ghost"
									size="sm"
									className="vessel-departments-statistics-trigger flex w-full items-center justify-between"
								>
									<h4 className="text-sm font-semibold">{department.name}</h4>
									<ChevronsUpDown className="h-4 w-4" />
									<span className="sr-only">Toggle</span>
								</Button>
							</CollapsibleTrigger>
						</div>
						<CollapsibleContent className="px-3">
							<DepartmentStatistics positionsStats={department.positionsStats} />
						</CollapsibleContent>
					</Collapsible>
				);
			})}
		</div>
	);
};
