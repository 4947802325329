import { apiSlice } from "@/app/api/apiSlice";

export const appConfigApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAppConfig: builder.query({
			query: () => `/api/config`,
			providesTags: ["VesselBranding"],
		}),
		getCalendarColors: builder.query({
			query: () => `/api/fleet-colors`,
			providesTags: ["CalendarColors"],
		}),
	}),
	overrideExisting: true,
});

export const { 
	useGetAppConfigQuery,
	useGetCalendarColorsQuery,
} = appConfigApiSlice;
