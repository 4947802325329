import { useState } from "react";

import { useAddFleetMutation } from "@/app/api/slices/admin/adminFleetApiSlice";
import { FleetUpsertForm } from "@/components/forms/FleetUpsertForm";
import { type FormType } from "@/components/forms/FleetUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const AddFleetDialog = () => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addFleetMutation, { isLoading }] = useAddFleetMutation();

	const handleAddFleet = async (data: FormType) => {
		const parsedData = {
			name: data.name,
			vessels: data.vessels.map((vessel) => Number(vessel.value)),
		};

		await addFleetMutation({ data: parsedData })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added fleet.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button className="uppercase">Add fleet</Button>
			</DialogTrigger>
			<DialogContent className="overflow-visible sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Add Fleet</DialogTitle>
				</DialogHeader>
				<FleetUpsertForm onSubmit={handleAddFleet} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};
