import { ChevronsUpDown, Download } from "lucide-react";
import { useState } from "react";

import { EditCrewMemberDocumentsDialog } from "@/components/dialogs/EditCrewMemberDocumentsDialog";
import { RemoveCrewMemberDocumentsDialog } from "@/components/dialogs/RemoveCrewMemberDocumentsDialog";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { type OpenStates } from "@/types/AccordionOpenStates";
import { type Document } from "@/types/Document";

type Props = {
	documents: Document[];
};

export const CrewMemberDocumentsAccordion = ({ documents }: Props) => {
	const { format } = useDateFormatter();
	const [openStates, setOpenStates] = useState<OpenStates>({});

	const toggleOpenState = (sectionId: number) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[sectionId]: !prevStates[sectionId],
		}));
	};

	return (
		<div className="flex flex-col gap-3">
			{documents.map((document) => {
				return (
					<Collapsible
						key={document.id}
						open={openStates[document.id] || false}
						onOpenChange={() => toggleOpenState(document.id)}
						className="space-y-2"
					>
						<div className="flex items-center justify-start space-x-4 sm:justify-between">
							<CollapsibleTrigger asChild>
								<Button
									variant="ghost"
									size="sm"
									className="flex h-auto w-full flex-wrap items-center justify-start py-2 sm:h-9 sm:flex-nowrap sm:justify-between"
								>
									<h4 className="w-36 truncate text-left text-sm font-semibold sm:w-auto">
										{document.title}
									</h4>
									<div className="flex flex-wrap items-center justify-start gap-1 text-xs sm:flex-nowrap sm:justify-center sm:gap-2 sm:text-sm">
										<span className="hidden font-normal sm:inline-block">
											<span className="font-semibold">Created at:</span>{" "}
											{format(document.createdAt)}
										</span>
										<span className="font-normal">
											<span className="font-semibold">Updated at:</span>{" "}
											{format(document.updatedAt)}
										</span>
										<span className="font-normal">
											<span className="font-semibold">Opened at:</span>{" "}
											{document?.openedAt ? format(document.openedAt) : "-"}
										</span>
										<ChevronsUpDown className="hidden h-4 w-4 sm:inline-block" />
									</div>
									<span className="sr-only">Toggle</span>
								</Button>
							</CollapsibleTrigger>
							<div className="flex items-center gap-1">
								<RemoveCrewMemberDocumentsDialog documentId={document.id} />
								<EditCrewMemberDocumentsDialog document={document} />
							</div>
						</div>
						<CollapsibleContent>
							<div className="px-3">
								<h3 className="font-semibold">Documents:</h3>
								<ul className="list-inside list-decimal">
									{document.files.map((file) => {
										return (
											<li
												key={file.name}
												className="my-1 flex items-center justify-between rounded-sm bg-gray-100 pl-2"
											>
												<span
													className="truncate text-sm"
													title={`${file.name} &ndash; uploaded by ${file.uploadedBy}`}
												>
													{file.name} &ndash; uploaded by {file.uploadedBy}
												</span>
												<div>
													<Button
														variant="outline"
														size="icon"
														asChild
														style={{ background: "#4CAF50", width: "28px", height: "28px" }}
													>
														<a className="flex items-center justify-center" href={file.url}>
															<Download color="#fff" size={20} />
														</a>
													</Button>
												</div>
											</li>
										);
									})}
								</ul>
							</div>
							<div className="px-3">
								<h3 className="font-semibold">Description:</h3>
								<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
									<span>{document.description}</span>
								</div>
							</div>
						</CollapsibleContent>
					</Collapsible>
				);
			})}
		</div>
	);
};
