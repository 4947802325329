import { Pen } from "lucide-react";
import { useState } from "react";

import { useUpdateAdminSectionMutation } from "@/app/api/slices/admin/adminKnowledgeBaseApiSlice";
import { useUpdateVesselSectionMutation } from "@/app/api/slices/manager/managerKnowledgeBaseApiSlice";
import { KnowledgeBaseSectionUpsertForm } from "@/components/forms/KnowledgeBaseSectionUpsertForm";
import { type FormType } from "@/components/forms/KnowledgeBaseSectionUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	section: { id: number; name: string };
};

export const EditKnowledgeBaseSectionDialog = ({ section }: Props) => {
	const auth = useAuth();
	const role = auth?.user?.role.name || "";
	const vesselId = useCurrentVesselId();
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateAdminSectionMutation, { isLoading: isUpdatingAdminSection }] =
		useUpdateAdminSectionMutation();
	const [updateVesselSectionMutation, { isLoading: isUpdatingVesselSection }] =
		useUpdateVesselSectionMutation();

	const handleUpdateSection = async ({ name }: FormType) => {
		if (["manager", "fleet_manager"].includes(role)) {
			await updateVesselSectionMutation({ vesselId, sectionId: section.id, name })
				.unwrap()
				.then((_payload) => {
					setOpen(false);
					toast({
						variant: "success",
						title: "Successfully updated section.",
					});
				})
				.catch((error) => handleServerErrors(error, toast));
			return;
		}

		await updateAdminSectionMutation({ name, sectionId: section.id })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated section.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#2055A3" }}>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Department</DialogTitle>
				</DialogHeader>
				<KnowledgeBaseSectionUpsertForm
					onSubmit={handleUpdateSection}
					isLoading={isUpdatingAdminSection || isUpdatingVesselSection}
					defaultValues={section}
				/>
			</DialogContent>
		</Dialog>
	);
};
