import { Trash2 } from "lucide-react";
import { useState } from "react";

import { useRemoveAdminArticleMutation } from "@/app/api/slices/admin/adminKnowledgeBaseApiSlice";
import { useRemoveVesselArticleMutation } from "@/app/api/slices/manager/managerKnowledgeBaseApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = { articleId: number };

export const RemoveKnowledgeBaseArticleDialog = ({ articleId }: Props) => {
	const auth = useAuth();
	const vesselId = useCurrentVesselId();
	const role = auth?.user?.role.name || "";
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [removeAdminArticleMutation, { isLoading: isRemovingAdminArticle }] =
		useRemoveAdminArticleMutation();
	const [removeVesselArticleMutation, { isLoading: isRemovingVesselArticle }] =
		useRemoveVesselArticleMutation();

	const handleRemoveArticle = async () => {
		if (["manager", "fleet_manager"].includes(role)) {
			await removeVesselArticleMutation({ vesselId, articleId })
				.unwrap()
				.then((_payload) => {
					setOpen(false);
					toast({
						variant: "success",
						title: "Successfully removed article.",
					});
				})
				.catch((error) => handleServerErrors(error, toast));
			return;
		}

		await removeAdminArticleMutation({ articleId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully removed article.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#F44336" }}>
					<Trash2 color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Are you absolutely sure?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. This will permanently delete article.
					</DialogDescription>
				</DialogHeader>
				<div className="flex justify-between">
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							Cancel
						</Button>
					</DialogClose>
					<Button
						onClick={handleRemoveArticle}
						disabled={isRemovingAdminArticle || isRemovingVesselArticle}
					>
						<ButtonLoader isLoading={isRemovingAdminArticle || isRemovingVesselArticle} />
						Continue
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};
