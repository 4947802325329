import { useAuth } from "@/hooks/useAuth";
import { useParams } from "react-router-dom";

export function useCurrentVesselId() {
	const auth = useAuth();
	const params = useParams();
	const role = auth?.user?.role;

	if (role?.name === "fleet_manager") {
		return auth?.user?.activeVesselId;
	}

	return auth?.user?.vessel?.id || params.vesselId;
}
