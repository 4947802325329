import { Pen } from "lucide-react";
import { useState } from "react";

import { useUpdateFleetMutation } from "@/app/api/slices/admin/adminFleetApiSlice";
import { FleetUpsertForm } from "@/components/forms/FleetUpsertForm";
import { type FormType } from "@/components/forms/FleetUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { type Fleet } from "@/types/Fleet";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	fleetId: number;
	fleet: Fleet;
};

export const EditFleetDialog = ({ fleetId, fleet }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateFleetMutation, { isLoading }] = useUpdateFleetMutation();

	const handleUpdateVessel = async (data: FormType) => {
		const parsedData = {
			name: data.name,
			vessels: data.vessels.map((vessel) => Number(vessel.value)),
		};

		await updateFleetMutation({ data: parsedData, fleetId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated fleet.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	const parsedFleet = {
		name: fleet.name,
		vessels: fleet.vessels.map((vessel) => ({
			label: vessel.name,
			value: String(vessel.id),
		})),
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#2055A3" }}>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Vessel</DialogTitle>
				</DialogHeader>
				<FleetUpsertForm
					onSubmit={handleUpdateVessel}
					isLoading={isLoading}
					defaultValues={parsedFleet}
					fleetId={fleetId}
				/>
			</DialogContent>
		</Dialog>
	);
};
