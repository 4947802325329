export const crewDataForJanuary = {
    departments: [
        {
            id: 1,
            name: "Deck",
            positions: [
                { 
                    id: 1, 
                    name: "Captain",
                    crew: [
                        {
                            isHOD: true,
                            id: 1,
                            name: "John Doe",
                            calendar: [
                                {
                                    duration: 1,
                                    end_date: "2024-01-01",
                                    start_date: "2024-01-01",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 12,
                                    end_date: "2024-01-02",
                                    start_date: "2024-01-13",
                                    type: "onboard_day"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-01-14",
                                    start_date: "2024-01-14",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 12,
                                    end_date: "2024-01-15",
                                    start_date: "2024-01-28",
                                    type: "rotational_leave"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-01-29",
                                    start_date: "2024-01-29",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 4,
                                    end_date: "2024-01-30",
                                    start_date: "2024-01-31",
                                    type: "onboard_day"
                                },
                            ]
                        },
                        {
                            isHOD: false,
                            id: 2,
                            name: "Wilbert Abenrnthy",
                            calendar: [
                                {
                                    duration: 1,
                                    end_date: "2024-01-01",
                                    start_date: "2024-01-01",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 12,
                                    end_date: "2024-01-02",
                                    start_date: "2024-01-13",
                                    type: "rotational_leave"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-01-14",
                                    start_date: "2024-01-14",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 12,
                                    end_date: "2024-01-15",
                                    start_date: "2024-01-28",
                                    type: "onboard_day"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-01-29",
                                    start_date: "2024-01-29",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 4,
                                    end_date: "2024-01-30",
                                    start_date: "2024-01-31",
                                    type: "rotational_leave"
                                },
                            ]
                        }
                    ],
                },
                { 
                    id: 2, 
                    name: "C/O",
                    crew: [
                        {
                            isHOD: false,
                            id: 3,
                            name: "Dorris Bashiron",
                            calendar: [
                                {
                                    duration: 1,
                                    end_date: "2024-01-01",
                                    start_date: "2024-01-01",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 19,
                                    end_date: "2024-01-02",
                                    start_date: "2024-01-13",
                                    type: "onboard_day"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-01-21",
                                    start_date: "2024-01-21",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 10,
                                    end_date: "2024-01-22",
                                    start_date: "2024-01-31",
                                    type: "rotational_leave"
                                }
                            ]
                        },
                        {
                            isHOD: false,
                            id: 4,
                            name: "Lela Bode",
                            calendar: [
                                {
                                    duration: 1,
                                    end_date: "2024-01-01",
                                    start_date: "2024-01-01",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 19,
                                    end_date: "2024-01-02",
                                    start_date: "2024-01-13",
                                    type: "rotational_leave"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-01-21",
                                    start_date: "2024-01-21",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 10,
                                    end_date: "2024-01-22",
                                    start_date: "2024-01-31",
                                    type: "onboard_day"
                                }
                            ]
                        }
                    ],
                }
            ],
        },
        {
            id: 2,
            name: "Engineer",
            positions: [{ 
                id: 4, 
                name: "C/E",
                crew: [
                    {
                        isHOD: true,
                        id: 5,
                        name: "Ava Christiansen",
                        calendar: [
                            {
                                duration: 12,
                                end_date: "2024-01-02",
                                start_date: "2024-01-13",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-14",
                                start_date: "2024-01-14",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 2,
                                end_date: "2024-01-15",
                                start_date: "2024-01-16",
                                type: "travel_day"
                            },
                            {
                                duration: 10,
                                end_date: "2024-01-16",
                                start_date: "2024-01-28",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-29",
                                start_date: "2024-01-29",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 5,
                                end_date: "2024-01-30",
                                start_date: "2024-01-31",
                                type: "onboard_day"
                            },
                        ]
                    },
                    {
                        isHOD: false,
                        id: 6,
                        name: "Matilde Hoeger",
                        calendar: [
                            {
                                duration: 12,
                                end_date: "2024-01-02",
                                start_date: "2024-01-13",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-14",
                                start_date: "2024-01-14",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-01-15",
                                start_date: "2024-01-28",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-29",
                                start_date: "2024-01-29",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 5,
                                end_date: "2024-01-30",
                                start_date: "2024-01-31",
                                type: "onboard_day"
                            },
                        ]
                    }
                ],
            }],
        },
        {
            id: 3,
            name: "Purser",
            positions: [{ 
                id: 5, 
                name: "Purser",
                crew: [
                    {
                        isHOD: true,
                        id: 7,
                        name: "Blade Cummerata",
                        calendar: [
                            {
                                duration: 1,
                                end_date: "2024-01-01",
                                start_date: "2024-01-01",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-01-02",
                                start_date: "2024-01-13",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-14",
                                start_date: "2024-01-14",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-01-15",
                                start_date: "2024-01-28",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-29",
                                start_date: "2024-01-29",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 4,
                                end_date: "2024-01-30",
                                start_date: "2024-01-31",
                                type: "onboard_day"
                            },
                        ]
                    }
                ],
            }],
        },
        {
            id: 4,
            name: "Interior",
            positions: [{ 
                id: 6, 
                name: "C/S",
                crew: [
                    {
                        isHOD: false,
                        id: 8,
                        name: "Tomasa Bergstrom",
                        calendar: [
                            {
                                duration: 1,
                                end_date: "2024-01-01",
                                start_date: "2024-01-01",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-01-02",
                                start_date: "2024-01-13",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-14",
                                start_date: "2024-01-14",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-01-15",
                                start_date: "2024-01-28",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-29",
                                start_date: "2024-01-29",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 4,
                                end_date: "2024-01-30",
                                start_date: "2024-01-31",
                                type: "onboard_day"
                            },
                        ]
                    },
                    {
                        isHOD: true,
                        id: 9,
                        name: "Davin Roob",
                        calendar: [
                            {
                                duration: 1,
                                end_date: "2024-01-01",
                                start_date: "2024-01-01",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-01-02",
                                start_date: "2024-01-13",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-14",
                                start_date: "2024-01-14",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-01-15",
                                start_date: "2024-01-28",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-01-29",
                                start_date: "2024-01-29",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 4,
                                end_date: "2024-01-30",
                                start_date: "2024-01-31",
                                type: "rotational_leave"
                            },
                        ]
                    }
                ],
            }],
        }
    ]
};

export const crewDataForFebruary = {
    departments: [
        {
            id: 1,
            name: "Deck",
            positions: [
                { 
                    id: 1, 
                    name: "Captain",
                    crew: [
                        {
                            isHOD: true,
                            id: 1,
                            name: "John Doe",
                            calendar: [
                                {
                                    duration: 10,
                                    end_date: "2024-02-10",
                                    start_date: "2024-02-01",
                                    type: "onboard_day"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-02-11",
                                    start_date: "2024-02-11",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 12,
                                    end_date: "2024-02-23",
                                    start_date: "2024-02-12",
                                    type: "rotational_leave"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-02-24",
                                    start_date: "2024-02-24",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 5,
                                    end_date: "2024-02-29",
                                    start_date: "2024-02-25",
                                    type: "onboard_day"
                                },
                            ]
                        },
                        {
                            isHOD: false,
                            id: 2,
                            name: "Wilbert Abenrnthy",
                            calendar: [
                                {
                                    duration: 10,
                                    end_date: "2024-02-10",
                                    start_date: "2024-02-01",
                                    type: "rotational_leave"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-02-11",
                                    start_date: "2024-02-11",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 12,
                                    end_date: "2024-02-23",
                                    start_date: "2024-02-12",
                                    type: "onboard_day"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-02-24",
                                    start_date: "2024-02-24",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 5,
                                    end_date: "2024-02-29",
                                    start_date: "2024-02-25",
                                    type: "rotational_leave"
                                },
                            ]
                        }
                    ],
                },
                { 
                    id: 2, 
                    name: "C/O",
                    crew: [
                        {
                            isHOD: false,
                            id: 3,
                            name: "Dorris Bashiron",
                            calendar: [
                                {
                                    duration: 9,
                                    end_date: "2024-02-01",
                                    start_date: "2024-02-09",
                                    type: "onboard_day"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-02-10",
                                    start_date: "2024-02-10",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 19,
                                    end_date: "2024-02-29",
                                    start_date: "2024-02-11",
                                    type: "rotational_leave"
                                }
                            ]
                        },
                        {
                            isHOD: false,
                            id: 4,
                            name: "Lela Bode",
                            calendar: [
                                {
                                    duration: 9,
                                    end_date: "2024-02-01",
                                    start_date: "2024-02-09",
                                    type: "rotational_leave"
                                },
                                {
                                    duration: 1,
                                    end_date: "2024-02-10",
                                    start_date: "2024-02-10",
                                    type: "travel_day_change_day"
                                },
                                {
                                    duration: 19,
                                    end_date: "2024-02-29",
                                    start_date: "2024-02-11",
                                    type: "onboard_day"
                                }
                            ]
                        }
                    ],
                }
            ],
        },
        {
            id: 2,
            name: "Engineer",
            positions: [{ 
                id: 4, 
                name: "C/E",
                crew: [
                    {
                        isHOD: true,
                        id: 5,
                        name: "Ava Christiansen",
                        calendar: [
                            {
                                duration: 7,
                                end_date: "2024-02-07",
                                start_date: "2024-02-01",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-08",
                                start_date: "2024-02-08",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 2,
                                end_date: "2024-02-10",
                                start_date: "2024-02-09",
                                type: "travel_day"
                            },
                            {
                                duration: 10,
                                end_date: "2024-02-20",
                                start_date: "2024-02-11",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-21",
                                start_date: "2024-02-21",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 8,
                                end_date: "2024-02-29",
                                start_date: "2024-02-21",
                                type: "onboard_day"
                            },
                        ]
                    },
                    {
                        isHOD: false,
                        id: 6,
                        name: "Matilde Hoeger",
                        calendar: [
                            {
                                duration: 7,
                                end_date: "2024-02-07",
                                start_date: "2024-02-01",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-08",
                                start_date: "2024-02-08",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-02-20",
                                start_date: "2024-02-09",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-21",
                                start_date: "2024-02-21",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 8,
                                end_date: "2024-02-29",
                                start_date: "2024-02-21",
                                type: "rotational_leave"
                            },
                        ]
                    }
                ],
            }],
        },
        {
            id: 3,
            name: "Purser",
            positions: [{ 
                id: 5, 
                name: "Purser",
                crew: [
                    {
                        isHOD: true,
                        id: 7,
                        name: "Blade Cummerata",
                        calendar: [
                            {
                                duration: 8,
                                end_date: "2024-02-08",
                                start_date: "2024-02-01",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-09",
                                start_date: "2024-02-09",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-02-21",
                                start_date: "2024-02-10",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-22",
                                start_date: "2024-02-22",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 7,
                                end_date: "2024-02-29",
                                start_date: "2024-02-23",
                                type: "onboard_day"
                            },
                        ]
                    }
                ],
            }],
        },
        {
            id: 4,
            name: "Interior",
            positions: [{ 
                id: 6, 
                name: "C/S",
                crew: [
                    {
                        isHOD: false,
                        id: 8,
                        name: "Tomasa Bergstrom",
                        calendar: [
                            {
                                duration: 8,
                                end_date: "2024-02-08",
                                start_date: "2024-02-01",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-09",
                                start_date: "2024-02-09",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-02-21",
                                start_date: "2024-02-10",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-22",
                                start_date: "2024-02-22",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 7,
                                end_date: "2024-02-29",
                                start_date: "2024-02-23",
                                type: "onboard_day"
                            },
                        ]
                    },
                    {
                        isHOD: true,
                        id: 9,
                        name: "Davin Roob",
                        calendar: [
                            {
                                duration: 8,
                                end_date: "2024-02-08",
                                start_date: "2024-02-01",
                                type: "rotational_leave"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-09",
                                start_date: "2024-02-09",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 12,
                                end_date: "2024-02-21",
                                start_date: "2024-02-10",
                                type: "onboard_day"
                            },
                            {
                                duration: 1,
                                end_date: "2024-02-22",
                                start_date: "2024-02-22",
                                type: "travel_day_change_day"
                            },
                            {
                                duration: 7,
                                end_date: "2024-02-29",
                                start_date: "2024-02-23",
                                type: "rotational_leave"
                            },
                        ]
                    }
                ],
            }],
        }
    ]
};
