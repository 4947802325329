import { apiSlice } from "@/app/api/apiSlice";

export const adminFleetApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getFleets: builder.query({
			query: () => `/api/fleets`,
			providesTags: ["Fleets"],
		}),
		addFleet: builder.mutation({
			query: ({ data }) => ({
				url: `/api/fleets`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Fleets", "Vessels"]),
		}),
		updateFleet: builder.mutation({
			query: ({ data, fleetId }) => ({
				url: `/api/fleets/${fleetId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Fleets", "Vessels"]),
		}),
		removeFleet: builder.mutation({
			query: ({ fleetId }) => ({
				url: `/api/fleets/${fleetId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Fleets", "Vessels"]),
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetFleetsQuery,
	useAddFleetMutation,
	useUpdateFleetMutation,
	useRemoveFleetMutation,
} = adminFleetApiSlice;
