import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useGetFleetUsersQuery } from "@/app/api/slices/fleet-manager/fleetApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { DialogClose } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { cn } from "@/lib/utils";
import { type FormProps } from "@/types/Form";
import { type User } from "@/types/User";
import { Check, ChevronsUpDown } from "lucide-react";
import { formSchema, type FormType } from "./formSchema";

type Props = FormProps<FormType> & {
	open: boolean;
};

export const SwapCrewMembersForm = ({
	onSubmit,
	isLoading,
	defaultValues = {
		crewMemberId: 0,
	},
	open,
}: Props) => {
	const vesselId = useCurrentVesselId();

	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const { handleSubmit, control } = form;

	const { data: usersResponse, isLoading: isLoadingUsers } = useGetFleetUsersQuery(
		{
			exclude: vesselId,
		},
		{ skip: !open },
	);

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="crewMemberId"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Crew Member to swap with</FormLabel>
											<FormMessage />
										</div>
										{!isLoadingUsers && (
											<Popover modal={true}>
												<PopoverTrigger asChild>
													<FormControl>
														<Button
															variant="outline"
															role="combobox"
															className={cn(
																"w-[200px] justify-between",
																!field.value && "text-muted-foreground",
															)}
														>
															{field.value
																? `${usersResponse?.data.find(
																		(user: User) => user.id === field.value,
																	).firstName} ${usersResponse?.data.find(
																		(user: User) => user.id === field.value,
																	).lastName}`
																: "Select crew member"}
															<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
														</Button>
													</FormControl>
												</PopoverTrigger>
												<PopoverContent className="w-[200px] p-0">
													<Command>
														<CommandInput placeholder="Search crew member..." />
														<CommandEmpty>No crew member found.</CommandEmpty>
														<CommandList>
															<CommandGroup>
																{usersResponse?.data.map((user: User) => (
																	<CommandItem
																		value={`${user.firstName} ${user.lastName}`}
																		key={`user-${user.id}`}
																		onSelect={() => {
																			form.setValue("crewMemberId", user.id);
																		}}
																	>
																		<Check
																			className={cn(
																				"mr-2 h-4 w-4",
																				user.id === field.value ? "opacity-100" : "opacity-0",
																			)}
																		/>
																		{user.firstName} {user.lastName}
																	</CommandItem>
																))}
															</CommandGroup>
														</CommandList>
													</Command>
												</PopoverContent>
											</Popover>
										)}
										{isLoadingUsers && <Skeleton className="h-10 w-full rounded-md" />}
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};
