import * as z from "zod";

export const formSchema = z.object({
	name: z.string().min(3, { message: "Fleet name must contain at least 3 characters" }),
	vessels: z
		.array(
			z.object({
				label: z.string(),
				value: z.string(),
			}),
		)
		.min(1, { message: "At least one vessel is required" }),
});

export type FormType = z.infer<typeof formSchema>;
