import { useEffect, useState } from "react";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { useParams  } from "react-router-dom";
import { Loader, CheckIcon } from "lucide-react";
import { addWeeks, format as formatFns } from "date-fns";
import * as Checkbox from '@radix-ui/react-checkbox';

import { useJoyride } from "@/contexts/JoyrideContext";
import { cn } from "@/lib/utils";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/components/ui/button";
import { WhiteBox } from "@/components/WhiteBox";
import { RemoveContractDialog } from "@/components/dialogs/RemoveContractDialog";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { 
	useGetCrewMemberContractsListQuery,
	useCreateCrewMemberContractMutation,
	useEditContractMutation
} from "@/app/api/slices/rotationCalendarApiSlice";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { type RotationCalendarData, type CrewMamberContractDataInterface, type RotationCalendarSettingsInterface, type ConfigDataInterface, type EditContractArgs, type CrewMemberRotationProps, type RotationCalendarUser } from "@/types/RotationCalendarInterfaces";
import { CalendarPicker } from "../ui/calendar-picker";
import { ChangeEndDateDialog } from "../dialogs/ChangeEndDateDialog";
import { joyride_redirect_3_index } from "@/hocs/JoyrideWrapper";
import { rotationCreatorSteps } from "@/data/joyride/rotation-creator";
import { LabelWithError } from "../calendar/Controls/LabelWithError";
import { RotationOverwriteDialog } from "@/components/dialogs/RotationOverwriteDialog";
import { Statistics } from "@/components/calendar/Controls/Statistics";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

const index_of_click_step = rotationCreatorSteps.findIndex(
	(step) => step.target === "#rotation-creator-add-contract-button",
);
const create_contract_step_index = joyride_redirect_3_index + index_of_click_step + 1;

const checkIfRotationIsValid = ({rotation, startDate, endDate} : {rotation: string | undefined | null, startDate: Date | undefined | null, endDate: Date | undefined | null}) => {
	if(!rotation || !startDate) return false;

	const rotationWeeks = rotation.split("/");
	const sumWeeks = rotationWeeks.reduce((acc, week) => acc + Number(week), 0);
	const calculatedDate = addWeeks(startDate, sumWeeks);
	if(endDate && (endDate < calculatedDate)) {
		return false;
	}
	return true;
}

const datesSchema = z.object({
	startDate: z.date().refine(value => value !== null, { message: "Start date is required" }),
	endDate: z.date().optional()
})

const rotationInfoSchema = z.object({
	isAutomaticallyGenerated: z.boolean().optional(),
	rotationPeriodType: z.string().trim().min(1, "Choose rotation period type"),
	rotation: z.string().optional(),
	leaveAllowance: z.string().optional(),
	rotation_partner_id: z.string().optional()
})
.refine(schema => {
	if(["weeks", "months"].includes(schema.rotationPeriodType || "") && !schema.rotation) return false;
	return true;
}, { message: "Choose rotation type", path: ["rotation"] })
.refine(schema => {
	if(["leave_allowance"].includes(schema.rotationPeriodType || "") && !schema.leaveAllowance) return false;
	return true;
}, { message: "Leave allowance is required", path: ["leaveAllowance"] })
.refine(schema => {
	if(["leave_allowance"].includes(schema.rotationPeriodType || "") && (Number(schema.leaveAllowance) < 0)) return false;
	return true;
}, { message: "Leave Allowance should be a number greater than or equal to 0", path: ["leaveAllowance"] });

const formSchema = z.intersection(datesSchema, rotationInfoSchema).refine(schema => {
	if(["weeks", "months"].includes(schema.rotationPeriodType || "") && schema.rotation) {
		return checkIfRotationIsValid({rotation: schema.rotation, startDate: schema.startDate, endDate: schema.endDate});
	}
	return true;
}, { message: "Rotation will not fit within the given range", path: ["endDate"] })

const defaultFormSchemaValues: {
	startDate: Date | undefined;
	endDate: Date | undefined;
	isAutomaticallyGenerated: boolean;
	rotationPeriodType: string | undefined;
	rotation: string | undefined;
	leaveAllowance: string | undefined;
	rotation_partner_id: string | undefined;
} = {
	startDate: undefined,
	endDate: undefined,
	isAutomaticallyGenerated: false,
	rotationPeriodType: '',
	rotation: '',
	leaveAllowance: '',
	rotation_partner_id: ''
}

interface Props {
	isLoadingSettings: boolean;
	isLoadingCalendar: boolean;
	isFetchingCalendar: boolean;
	rotationCalendarSettings: RotationCalendarSettingsInterface | undefined;
	configData: ConfigDataInterface | undefined;
	isMobile: boolean;
	setPage: React.Dispatch<React.SetStateAction<number | undefined>>;
	selectedContract: RotationCalendarData | undefined;
	selectedContractId: number | string | undefined | null;
	setSelectedContract: React.Dispatch<React.SetStateAction<RotationCalendarData | undefined>>;
	setSelectedContractId: React.Dispatch<React.SetStateAction<string | number | null | undefined>>
}

export const Controls = ({
	isLoadingSettings,
	isLoadingCalendar,
	isFetchingCalendar,
	rotationCalendarSettings,
	configData,
	isMobile,
	setPage,
	selectedContract,
	selectedContractId,
	setSelectedContract,
	setSelectedContractId
} : Props) => {
	const { format } = useDateFormatter();
	const auth = useAuth();
	const params = useParams();
	const vessel_id = useCurrentVesselId();
	const leaveBalaceType = auth?.user?.vessel?.leave_balance_type;
	const { user_id } = params;

	const [openWarningDialog, setOpenWarningDialog] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isExtendModalOpen, setIsExtendModalOpen] = useState(false);

	const form = useForm({
		resolver: zodResolver(formSchema),
		defaultValues: defaultFormSchemaValues
	});
	const { handleSubmit, control, reset, setValue, watch, formState: { errors } } = form;
	const rotationPeriodTypeWatch = watch("rotationPeriodType");
	const rotationWatch = watch("rotation");
	const startDateWatch = watch("startDate");
	const endDateWatch = watch("endDate");

	const { toast } = useToast();

	const {
		data: contractsData,
		isLoading: isLoadingContracts,
		isFetching: isFetchingContracts,
	} = useGetCrewMemberContractsListQuery({ vessel_id: vessel_id || 0, user_id: user_id || 0 }, { skip: !vessel_id || !user_id });

	const [createCrewMemberRotation, { isLoading: isCreatingContract }] = useCreateCrewMemberContractMutation();
	const [editCrewMemberRotation, 
		{ isLoading: isEditingRotation }
	] = useEditContractMutation();

	const onAddNewContractHandler_2 = () => {
		setTimeout(() => {
			setStepIndex(create_contract_step_index);
		}, 400)

		setSelectedContractId(undefined);
		setSelectedContract(undefined);

		setIsEditMode(true);
	}

	// TODO: Fix types
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const setFormValues = (contract: any) => {
		reset({
			startDate: contract.start_date ? new Date(contract.start_date) : defaultFormSchemaValues.startDate,
			endDate: contract.end_date ? new Date(contract.end_date) : defaultFormSchemaValues.endDate,
			isAutomaticallyGenerated: contract.isAutomaticallyGenerated ? !!contract.isAutomaticallyGenerated : defaultFormSchemaValues.isAutomaticallyGenerated,
			rotationPeriodType: contract.rotationPeriodType ? contract.rotationPeriodType : defaultFormSchemaValues.rotationPeriodType,
			rotation: contract.rotation ? contract.rotation : defaultFormSchemaValues.rotation,
			leaveAllowance: contract.leaveAllowance ? contract.leaveAllowance : defaultFormSchemaValues.leaveAllowance,
			rotation_partner_id: contract.rotation_partner ? String(contract.rotation_partner.id) : defaultFormSchemaValues.rotation_partner_id,
		});
	}

	const selectedContractIdHandler = async (id: number | undefined | null) => {
		if(isEditMode || isLoadingCalendar || isLoadingSettings || isCreatingContract) return;

		setSelectedContractId(id);

		const contract = contractsData?.data?.contracts.find((contract: CrewMamberContractDataInterface) => contract.id === id);
		if(contract) {
			setSelectedContract(contract);
			setFormValues(contract);
		}
	}

	const activateEditModeHandler = () => {
		setIsEditMode(true);
	}

	const cancelEditModeHandler = () => {
		setIsEditMode(false);

		if(selectedContractId) {
			const contract = contractsData?.data?.contracts.find((contract: CrewMamberContractDataInterface) => contract.id === selectedContractId);
			if(contract) {
				setFormValues(contract);
			}
		}
	}

	const editCrewMemberContractHandler = async ({vessel_id, user_id, data} : EditContractArgs) => {
		await editCrewMemberRotation({
			vessel_id,
			user_id,
			data
		}).unwrap()
		.then((_payload) => {
			setIsEditMode(false);
			setOpenWarningDialog(false);
			
			toast({
				variant: "success",
				title: "Successfully updated leave plan.",
			});
		})
		.catch((error) => handleServerErrors(error, toast));
	}

	const createCrewMemberRotationHandler = async ({vessel_id, user_id, rotation, start_date, end_date, rotation_partner_id, isAutomaticallyGenerated, rotationPeriodType, leaveAllowance } : CrewMemberRotationProps) => {
		await createCrewMemberRotation({
			vessel_id, 
			user_id, 
			data: {
				rotation, 
				start_date, 
				end_date, 
				rotation_partner_id,
				isAutomaticallyGenerated,
				rotationPeriodType,
				leaveAllowance
			}
		})
			.unwrap()
			.then((_payload) => {
				setValue("endDate", end_date ? new Date(end_date) : defaultFormSchemaValues.endDate);
				setIsEditMode(false);

				toast({
					variant: "success",
					title: "Successfully created leave plan.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	}

	const saveRotationHandler = () => {
		if(vessel_id === undefined || user_id === undefined) return;

		handleSubmit((data) => {
			if(!data.rotationPeriodType) return;

			if(selectedContractId){
				editCrewMemberContractHandler({
					vessel_id: vessel_id,
					user_id: user_id,

					data: {
						id: selectedContractId,
						rotation: data.rotation ? data.rotation : null,
						start_date: data.startDate ? formatFns(data.startDate, "y-MM-dd") : "",
						end_date: data.endDate ? formatFns(data.endDate, "y-MM-dd") : null,
						isAutomaticallyGenerated: data.isAutomaticallyGenerated,
						rotationPeriodType: data.rotationPeriodType,
						leaveAllowance: data.leaveAllowance ? Number(data.leaveAllowance) : null,
						rotation_partner_id: !checkIfRotationIsInvalidForPartner(data.rotation) && data.rotation_partner_id && (data.rotation_partner_id !== "null") ? data.rotation_partner_id : null
				}});
				return;
			}
			
			createCrewMemberRotationHandler({
				vessel_id: String(vessel_id),
				user_id: String(user_id),
				rotation: data.rotation ? data.rotation : null,
				start_date: data.startDate ? formatFns(data.startDate, "y-MM-dd") : "",
				end_date: data.endDate ? formatFns(data.endDate, "y-MM-dd") : null,
				isAutomaticallyGenerated: data.isAutomaticallyGenerated,
				rotationPeriodType: data.rotationPeriodType,
				leaveAllowance: data.leaveAllowance ? Number(data.leaveAllowance) : null,
				rotation_partner_id: data.rotation_partner_id && (data.rotation_partner_id !== "null") ? data.rotation_partner_id : null
			});
		})();
	}

	const checkIfRotationIsInvalidForPartner = (rotation: string | undefined) => {
		if(!rotation) return true;

		const rotationWeeks = rotation.split("/");
		if(rotationWeeks[0] === rotationWeeks[1]) return false;

		return true
	}

	const rotationOptions = (rotationPeriodType: string | undefined) => {
		// allow to select rotation length ie: 4/4, 3/1
		if(!rotationPeriodType) return null;

		switch(rotationPeriodType) {
			case "weeks":
				return configData?.data?.rotationTypes?.map((rotation: string, index: number) => (
					<SelectItem key={index} value={rotation}>{rotation}</SelectItem>
				));
			case "months":
				return configData?.data?.rotationTypesMonths?.map((rotation: string, index: number) => (
					<SelectItem key={index} value={rotation}>{rotation}</SelectItem>
				));
			default:
				return null;
		}
	}

	const saveEditedRotationHandler = () => {
		handleSubmit(() => {
			setOpenWarningDialog(true);
		})();
	}

	useEffect(() => {
		const contracts = contractsData?.data?.contracts;
		if(Array.isArray(contracts) && contracts.length) {
			const id = contracts[0].id;
			if(id) {
				setSelectedContractId(id);
				const contract = contractsData?.data?.contracts.find((contract: CrewMamberContractDataInterface) => contract.id === id);
				if(contract) {
					setSelectedContract(contract);
					setPage(contract.current_page || 1);
					setFormValues(contract);
				}
			}
		} else {
			setSelectedContractId(undefined);
			setSelectedContract(undefined);

			reset(defaultFormSchemaValues);
		}
	}, [contractsData]);

	// JOYRIDE STARTER
	const { run, startJoyride, stepIndex, setStepIndex } = useJoyride();
	useEffect(() => {
		if (!isMobile && !run && !isLoadingContracts && !isLoadingSettings && stepIndex === joyride_redirect_3_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [isLoadingContracts, isLoadingSettings]);
	// END JOYRIDE STARTER

	return (
		<>
			<ChangeEndDateDialog 
				isExtendModalOpen={isExtendModalOpen}
				setIsExtendModalOpen={setIsExtendModalOpen}
				selectedContractId={selectedContractId}
				selectedContract={selectedContract}
			/>

			<RotationOverwriteDialog 
				openWarningDialog={openWarningDialog} 
				setOpenWarningDialog={setOpenWarningDialog}
				isEditingRotation={isEditingRotation}
				saveRotationHandler={saveRotationHandler}
			/>

			<WhiteBox className={cn("mb-4 relative", isMobile && "border")}>
				<div>
					<span className="font-bold">Leave plans</span>
				</div>
				<hr className="my-1 mb-3"/>

				<div className="flex flex-col gap-1 h-11 overflow-auto pe-2">
					{ !isLoadingContracts && contractsData?.data?.contracts?.length === 0 && (
						<div className="flex justify-center items-center grow" >
							<span className="text-sm">Leave plan has not been created yet</span>
						</div>
					)}
					
					{contractsData?.data?.contracts?.map((contract: CrewMamberContractDataInterface) => {
						return (
							<div key={contract.id} onClick={() => selectedContractIdHandler(contract.id)}
							className={cn("cursor-pointer pe-2 ps-3 py-1 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground font-semibold flex justify-between items-center", selectedContractId === contract.id ? "bg-slate-200" : "", (isEditMode || isCreatingContract || isLoadingSettings || isLoadingContracts || isLoadingCalendar || isFetchingCalendar) ? "cursor-not-allowed" : "" )}>

								<div className="w-9">{contract.rotation}</div>
								<div className="flex flex-col me-3">
									{contract.end_date && (
										<>
											<p>&#40;{format(contract.start_date)} to {format(contract.end_date)}&#41;</p>
										</>
									)} 

									{!contract.end_date && (
										<>
											<p>&#40;from {format(contract.start_date)}&#41;</p>
										</>
									)}
								</div>

								<div className="flex items-center">
									<RemoveContractDialog contractId={contract.id} userId={user_id} vesselId={vessel_id} disabled={isEditMode || isCreatingContract || isLoadingSettings || isLoadingContracts || isLoadingCalendar || isFetchingCalendar}/>
								</div>
							</div>
						);
					})}
				</div>

				<hr className="my-1 mt-3"/>

				<div>
					<Button id={!isMobile ? `rotation-creator-add-contract-button` : ""} className="mt-3 w-full" disabled={ contractsData && (contractsData?.data?.contracts?.length >= 1) || isEditMode || isCreatingContract || isLoadingSettings || isLoadingContracts || isLoadingCalendar || isFetchingCalendar || isFetchingContracts} onClick={ onAddNewContractHandler_2 } >
						<ButtonLoader isLoading={isCreatingContract || isLoadingSettings || isLoadingContracts || isLoadingCalendar || isFetchingCalendar || isFetchingContracts}/>
						Create leave plan
					</Button>
				</div>
			</WhiteBox>
		
			<WhiteBox className={cn("mb-4 relative", isMobile && "border")}>
				<div className="flex flex-col" style={{minHeight: 496}}>
					<div>
						<span className="font-bold">{rotationCalendarSettings?.data?.user?.position?.name ?? "-"}</span>
					</div>
					<div>
						<span className="text-sm">{rotationCalendarSettings?.data?.user?.name ?? "-"}</span>
					</div>
					<hr className="my-1"/>

					{ (isLoadingSettings || isLoadingContracts) && (
						<div className="z-10 absolute inset-0 flex h-full w-full items-center justify-center rounded-md" style={{background: "rgba(255,255,255,0.6)", backdropFilter: "blur(2px)"}}>
							<Loader className="animate-spin duration-2000" color="#000" />
						</div>
					)}

					{ (!isLoadingSettings && !isLoadingContracts && !isEditMode && !selectedContract) && (
						<div className="flex grow items-center justify-center">
							<span className="text-sm">Select a leave plan or add a new one</span>
						</div>
					)}

					{ (!isLoadingSettings && !isLoadingContracts && (isEditMode || selectedContract)) && (
						<>
							<div>
								<div id={!isMobile ? `rotation-creator-start-date-picker` : ""} className="mt-3">
									<LabelWithError label="Start Date" error={errors?.startDate?.message} />
									<Controller 
										control={control}
										name="startDate"
										render={({field}) => (
											<CalendarPicker 
												disabled={!isEditMode || isCreatingContract}
												placeholder="Select start date"

												onSelect={(date) => field.onChange(date)}
												selected={field.value}

												defaultMonth={startDateWatch ? startDateWatch : new Date()}
											/>
										)}
									/>
								</div>

								<div className="mt-2">
									<LabelWithError label="End Date (optional)" error={errors?.endDate?.message} />
									<Controller 
										control={control}
										name="endDate"
										render={({field}) => (
											<CalendarPicker 
												disabled={!isEditMode || isCreatingContract}
												placeholder="Select start date"

												onSelect={(date) => field.onChange(date)}
												selected={field.value}

												defaultMonth={endDateWatch ? endDateWatch : new Date()}
											/>
										)}
									/>
								</div>

								<div className="mt-2 flex justify-between" >
									<label className="text-sm mb-1">Generate rotations automatically</label>
									<div>
										<Controller 
											control={control}
											name="isAutomaticallyGenerated"
											render={({field}) => (
												<Checkbox.Root id={!isMobile ? `rotation-creator-manual-end-date-checkbox` : ""} className="w-10 h-10 flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
													onCheckedChange={(value) => field.onChange(value)}
													checked={field.value}
													disabled={!isEditMode || isCreatingContract}
												>
													<Checkbox.Indicator className="CheckboxIndicator">
														<CheckIcon />
													</Checkbox.Indicator>
												</Checkbox.Root>
											)}
										/>
									</div>
								</div>

								<div id={!isMobile ? `rotation-creator-rotation-select` : ""} className="mt-2">
									<LabelWithError label="Rotation Type" error={errors?.rotationPeriodType?.message} />
									<Controller 
										control={control}
										name="rotationPeriodType"
										render={({field}) => (
											<Select 
												onValueChange={(value) => {
													field.onChange(value)

													setValue("rotation", defaultFormSchemaValues.rotation);
													setValue("rotation_partner_id", defaultFormSchemaValues.rotation_partner_id);
													setValue("leaveAllowance", defaultFormSchemaValues.leaveAllowance);
												}} 
												value={field.value} 
												disabled={!isEditMode || isCreatingContract}
											>
												<SelectTrigger>
													<SelectValue />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														{configData?.data?.rotationPeriodTypes?.map((rotation: {type: string, label: string}, index: number) => (
															<SelectItem key={index} value={rotation.type}>{rotation.label}</SelectItem>
														))}
													</SelectGroup>
												</SelectContent>
											</Select>
										)}
									/>
								</div>

								{ ["leave_allowance", "weeks", "months"].includes(rotationPeriodTypeWatch || "") && (
									<div id={!isMobile ? `rotation-creator-rotation-select` : ""} className="mt-2">
										<LabelWithError 
											label={["weeks", "months"].includes(rotationPeriodTypeWatch || "") ? "Leave Balanace Compensation" : "Leave Allowance" }
											error={errors?.leaveAllowance?.message} 
											info={["weeks", "months"].includes(rotationPeriodTypeWatch || "") ? "Box for leave balance from previous years (optional)" : "" }
										/>
										<Controller 
											control={control}
											name="leaveAllowance"
											render={({field}) => (
												<Input type="number" {...field} disabled={!isEditMode || isCreatingContract} />
											)}
										/>
									</div>
								)}
								
								{ ["weeks", "months"].includes(rotationPeriodTypeWatch || "") && (
									<>
										<div id={!isMobile ? `rotation-creator-rotation-select` : ""} className="mt-2">
											<LabelWithError label="Rotation" error={errors?.rotation?.message} />
											<Controller 
												control={control}
												name="rotation"
												render={({field}) => (
													<Select 
														onValueChange={(value) => {
															field.onChange(value);

															if(checkIfRotationIsInvalidForPartner(value)) {
																setValue("rotation_partner_id", defaultFormSchemaValues.rotation_partner_id);
															}
														}} 
														value={field.value} 
														disabled={!isEditMode || isCreatingContract}
													>
														<SelectTrigger>
															<SelectValue />
														</SelectTrigger>
														<SelectContent>
															<SelectGroup>
																{ rotationOptions(rotationPeriodTypeWatch) }
															</SelectGroup>
														</SelectContent>
													</Select>
												)}
											/>
										</div>

										{ !checkIfRotationIsInvalidForPartner(rotationWatch) && (
											<div id={!isMobile ? `rotation-creator-partner-select` : ""} className="mt-2">
												<LabelWithError label="Partner (optional)" error={errors?.rotation_partner_id?.message} />
												<Controller 
													control={control}
													name="rotation_partner_id"
													render={({field}) => (
														<Select 
															onValueChange={(value) => field.onChange(value)} 
															value={field.value} 
															disabled={!isEditMode || isCreatingContract}
														>
															<SelectTrigger>
																<SelectValue />
															</SelectTrigger>
															<SelectContent>
																<SelectGroup>
																	<SelectItem value='null'>&nbsp;</SelectItem>
																	{rotationCalendarSettings?.data?.possible_partners?.map((partner: RotationCalendarUser) => (
																		<SelectItem key={partner.id} value={String(partner.id)}>{partner.name}</SelectItem>
																	))}
																</SelectGroup>
															</SelectContent>
														</Select>
													)}
												/>
											</div>
										)}
									</>
								)}
							</div>

							<hr className="my-1 mt-3"/>

							<div id={!isMobile ? `rotation-creator-rotation-stats` : ""}>
								<Statistics 
									type="rotation"
									number_of_rotations={selectedContract?.estimated_number_of_rotations}
									number_of_travel_days={selectedContract?.estimated_number_of_travel_days}
									number_of_days_onboard={selectedContract?.estimated_number_of_days_onboard}
									number_of_days_leave={selectedContract?.estimated_number_of_days_leave}
									rotationPeriodType={selectedContract?.rotationPeriodType || ""}
									leaveBalaceType={leaveBalaceType}
									leave_balance_1_onboard={selectedContract?.leave_balance_1_onboard}
									leave_balance_1_offboard={selectedContract?.leave_balance_1_offboard}
									leave_balance_2={selectedContract?.leave_balance_2}
									leaveAllowance={selectedContract?.leaveAllowance}
									leaveAllowanceBalance={selectedContract?.leaveAllowanceBalance}
								/>
							</div>

							<div className={cn("grid gap-2", isEditMode ? "grid-cols-2" : "grid-cols-1")}>
								{ isEditMode && (
									<Button disabled={isLoadingSettings || isCreatingContract} variant="secondary" className="mt-3 w-full" onClick={cancelEditModeHandler}>Cancel</Button>
								)}

								{ (isEditMode && selectedContractId) && (
									<Button id={!isMobile ? `rotation-creator-save-button` : ""} disabled={isEditingRotation} className="mt-3 w-full" onClick={saveEditedRotationHandler}>
										<ButtonLoader isLoading={isLoadingSettings || isCreatingContract || isLoadingCalendar || isFetchingCalendar}/>
										Save
									</Button>
								)}

								{ (isEditMode && !selectedContractId) && (
									<Button id={!isMobile ? `rotation-creator-save-button` : ""} onClick={ saveRotationHandler } disabled={isCreatingContract} className="mt-3 w-full">
										<ButtonLoader isLoading={isCreatingContract}/>
										Save
									</Button>
								)}

								{ !isEditMode && (
									<Button disabled={isLoadingSettings || isCreatingContract || isLoadingCalendar || isFetchingCalendar} className="mt-3 w-full" onClick={ activateEditModeHandler }>
										<ButtonLoader isLoading={isLoadingSettings || isCreatingContract || isLoadingCalendar || isFetchingCalendar}/>
										{ isLoadingSettings || isCreatingContract ? "" : "Overwrite leave plan" }
									</Button>
								)}

								{ !isEditMode && selectedContractId && (
									<Button onClick={() => setIsExtendModalOpen(true)} 
										disabled={isLoadingSettings || isCreatingContract || isLoadingCalendar || isFetchingCalendar} 
										className="w-full"
									>
										<ButtonLoader isLoading={isCreatingContract}/>
										Change end date
									</Button>
								)}
							</div>
						</>
					)}
				</div>
			</WhiteBox>
		</>
	);
};
