import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = import.meta.env.VITE_API_URL;

const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	mode: "cors",
	credentials: "include",
	prepareHeaders: (headers) => {
		if (!Cookies.get("XSRF-TOKEN")) {
			axios
				.get(`${BASE_URL}/sanctum/csrf-cookie`, {
					withCredentials: true,
				})
				.catch((err) => {
					console.error(err);
				});
		}

		headers.set("X-Requested-With", "XMLHttpRequest");
		return headers;
	},
});

export const apiSlice = createApi({
	baseQuery: baseQuery,
	tagTypes: [
		"Vessels",
		"DepartmentsAndPositions",
		"AdminKnowledgeBaseSections",
		"AdminKnowledgeBaseArticles",
		"ManagerKnowledgeBaseSections",
		"ManagerKnowledgeBaseArticles",
		"AdminSuggestions",
		"ManagerSuggestions",
		"Managers",
		"Users",
		"UserDocuments",
		"VesselBranding",
		"RotationCalendar",
		"RotationSettings",
		"RotationContracts",
		"AvailablePositions",
		"CrewMemberStatistics",
		"PositionData",
		"Subscriptions",
		"UserSubscription",
		"UserRequests",
		"MagicLinks",
		"MyVesselInfo",
		"LeaveBalance",
		"CalendarColors",
		"CurrentUser",
		"CalendarColors",
		// fleets related
		"FleetVessels", // manager
		"FleetUsers", // manager
		"Fleets", // admin
	],
	endpoints: (_builder) => ({}),
});

// const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
// 	args,
// 	api,
// 	extraOptions,
// ) => {
// 	let result = await baseQuery(args, api, extraOptions);
// 	if (result.error && result.error.status === 401) {
// 		// try to get a new token
// 		const refreshResult = await baseQuery("/api/profile", api, extraOptions);
// 		if (refreshResult.data) {
// 			// // store the new token
// 			// api.dispatch(tokenReceived(refreshResult.data));
// 			// // retry the initial query
// 			result = await baseQuery(args, api, extraOptions);
// 		} else {
// 			// api.dispatch(loggedOut());
// 		}
// 	}
// 	return result;
// };
