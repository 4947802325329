import * as z from "zod";

export const formSchema = z.object({
	vessel: z.string().min(1, { message: "Vessel is required" }),
	crewMember: z.string().min(1, { message: "Crew Member is required" }),
	department: z.string().min(1, { message: "Department is required" }),
	position: z.string().min(1, { message: "Position is required" }),
	leavePlan: z.string().min(1, { message: "Leave Plan is required" }),
});

export type FormType = z.infer<typeof formSchema>;
