import { Bell } from "lucide-react";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useGetUserRequestsQuery } from "@/app/api/slices/manager/notificationsApiSlice";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { type UserRequest } from "@/types/UserRequest";

// Pusher.logToConsole = import.meta.env.VITE_IS_TEST;

export const NotificationsBell = () => {
	const auth = useAuth();
	const vesselId = useCurrentVesselId();
	const [notificationsAmount, setNotificationsAmount] = useState(0);

	const { data: response, isLoading } = useGetUserRequestsQuery({ vesselId });

	useEffect(() => {
		setNotificationsAmount(
			response?.data.filter((item: UserRequest) => item.status === "pending").length,
		);
	}, [response, isLoading]);

	useEffect(() => {
		const pusher = new Pusher("92115caff344068cf1d2", {
			cluster: "eu",
		});

		const channel = pusher.subscribe(`rotat-${auth?.user?.vessel?.id}`);
		channel.bind("notification", function (data: { notificationsAmount: number }) {
			setNotificationsAmount(data.notificationsAmount);
		});
	}, []);

	return (
		<Button id="notifications-button" asChild size="icon" className="relative">
			<Link to="notifications">
				<Bell />
				{notificationsAmount > 0 && (
					<div className="absolute -right-1.5 -top-1.5 flex aspect-square h-5 w-5 items-center justify-center rounded-full bg-red-500">
						<span>{notificationsAmount}</span>
					</div>
				)}
			</Link>
		</Button>
	);
};
