import { type ColumnDef } from "@tanstack/react-table";

import { EditFleetDialog } from "@/components/dialogs/EditFleetDialog";
import { RemoveFleetDialog } from "@/components/dialogs/RemoveFleetDialog";
import { type Fleet } from "@/types/Fleet";
import { type Vessel } from "@/types/Vessel";

export const columns: ColumnDef<Fleet>[] = [
	{
		accessorKey: "name",
		header: "Name",
		cell: ({ row }) => <div className="capitalize">{row.getValue("name")}</div>,
	},
	{
		accessorKey: "vessels",
		header: "Vessels",
		cell: ({ row }) => {
			const vessels = row.getValue("vessels") as Vessel[];

			return (
				<div>
					{vessels.map((vessel) => {
						return (
							<div key={vessel.id} className="flex items-center gap-2">
								<div>{vessel.name}</div>
							</div>
						);
					})}
				</div>
			);

			// <Button variant="outline" size="icon" asChild style={{ background: "#4CAF50" }}>
			//     <Link to={`/vessel/${vesselId}/branding`}>
			//         <Ship color="#fff" />
			//     </Link>
			// </Button>
			// <Button variant="outline" size="icon" asChild style={{ background: "#5bc0be" }}>
			//     <Link to={`/vessel/${vesselId}/departments-and-positions`}>
			//         <GitFork color="#fff" />
			//     </Link>
			// </Button>
			// <Button variant="outline" size="icon" asChild style={{ background: "#FB8500" }}>
			//     <Link to={`/vessel/${vesselId}/payments`}>
			//         <CreditCard color="#fff" />
			//     </Link>
			// </Button>
			// <Button variant="outline" size="icon" asChild style={{ background: "#ffbe0b" }}>
			//     <Link to={`/vessel/${vesselId}/managers`}>
			//         <UserCog color="#fff" />
			//     </Link>
			// </Button>
			// <Button variant="outline" size="icon" asChild style={{ background: "#8ECAE6" }}>
			//     <Link to={`/vessel/${vesselId}/crew-members`}>
			//         <Users color="#fff" />
			//     </Link>
			// </Button>
		},
	},
	{
		id: "actions",
		header: () => <div>Actions</div>,
		cell: ({ row }) => {
			const fleetId = row.original.id;
			console.log("first", row.original);

			return (
				<div className="flex justify-center gap-2">
					<EditFleetDialog fleetId={fleetId} fleet={{ ...row.original }} />
					<RemoveFleetDialog fleetId={fleetId} />
				</div>
			);
		},
	},
];
