import { Loader, Ship } from "lucide-react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
	useChangeActiveVesselMutation,
	useGetFleetVesselsQuery,
} from "@/app/api/slices/fleet-manager/fleetApiSlice";
import { authSlice } from "@/app/slices/authSlice";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { type Vessel } from "@/types/Vessel";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const VesselSelect = () => {
	const auth = useAuth();
	const dispatch = useDispatch();
	const activeVesselId = auth?.user?.activeVesselId;

	const [defValue, setDefValue] = useState<string | undefined>(String(activeVesselId));

	const { data: vesselsResponse, isLoading: isLoadingVessels } = useGetFleetVesselsQuery({});
	const [changeVesselMutation, { isLoading }] = useChangeActiveVesselMutation();

	const handleChange = async (value: string) => {
		setDefValue(value);
		dispatch(authSlice.actions.changeActiveVessel(Number(value)));
		await changeVesselMutation({ id: value })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Successfully changed active vessel.",
				});
			})
			.catch((error) => {
				handleServerErrors(error, toast);
				dispatch(authSlice.actions.changeActiveVessel(activeVesselId));
				setDefValue(String(activeVesselId));
			});
	};

	return (
		<div className="flex items-center gap-0">
			<div className="flex h-8 w-8 items-center">
				{isLoading ? (
					<Loader className="animate-spin text-white duration-2000" />
				) : (
					<Ship className="text-white" />
				)}
			</div>
			{!isLoadingVessels && (
				<Select
					onValueChange={handleChange}
					defaultValue={String(defValue)}
					value={String(defValue)}
				>
					<SelectTrigger className="h-6 w-[180px]">
						<SelectValue />
					</SelectTrigger>
					<SelectContent>
						<SelectGroup>
							<SelectLabel>Choose vessel</SelectLabel>
							{vesselsResponse?.data.map((vessel: Vessel) => (
								<SelectItem key={vessel.id} value={String(vessel.id)}>
									{vessel.name}
								</SelectItem>
							))}
						</SelectGroup>
					</SelectContent>
				</Select>
			)}
			{isLoadingVessels && <Skeleton className="h-6 w-[180px]" />}
		</div>
	);
};
