import { Trash2 } from "lucide-react";
import { useState } from "react";

import { useRemoveCrewMemberDocumentMutation } from "@/app/api/slices/documentsApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	documentId: number;
	onSuccessCallback?: () => void;
	userId: string | undefined;
};

export const RemoveCrewMemberDocumentsPositionsDialog = ({
	documentId,
	onSuccessCallback = () => {},
	userId,
}: Props) => {
	const vesselId = useCurrentVesselId();

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [removeCrewMemberDocumentMutation, { isLoading }] = useRemoveCrewMemberDocumentMutation();

	const handleRemoveDocuments = async () => {
		if (!userId) return;
		await removeCrewMemberDocumentMutation({ vesselId, userId, documentId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully removed document.",
				});
				onSuccessCallback();
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#F44336" }}>
					<Trash2 color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Are you absolutely sure?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. This will permanently delete documents.
					</DialogDescription>
				</DialogHeader>
				<div className="flex justify-between">
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							Cancel
						</Button>
					</DialogClose>
					<Button onClick={handleRemoveDocuments} disabled={isLoading}>
						<ButtonLoader isLoading={isLoading} />
						Continue
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};
