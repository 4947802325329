import { Replace } from "lucide-react";
import { useState } from "react";

import { useGetVesselQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { useSwapCrewMembersMutation } from "@/app/api/slices/crewMembersApiSlice";
import { SwapCrewMembersForm } from "@/components/forms/SwapCrewMembersForm";
import { type FormType } from "@/components/forms/SwapCrewMembersForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = { userId: number };

export const SwapCrewMembersDialog = ({ userId }: Props) => {
	const auth = useAuth();
	const vesselId = useCurrentVesselId();
	const role = auth?.user?.role?.name || "";

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [swapCrewMembersMutation, { isLoading }] = useSwapCrewMembersMutation();

	const { data: vesselResponse, isLoading: isLoadingVessel } = useGetVesselQuery(
		{ vesselId },
		{ skip: !vesselId },
	);

	const handleSwapCrewMember = async (data: FormType) => {
		await swapCrewMembersMutation({ vesselId, userId, data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added crew member.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	if (isLoadingVessel || !["fleet_manager"].includes(role) || !vesselResponse?.data?.fleet) {
		return null;
	}

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#c17bf6" }}>
					<Replace color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent
				id="add-crew-member-dialog"
				className="sm:max-w-[500px]"
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
			>
				<DialogHeader>
					<DialogTitle>Swap Crew Members</DialogTitle>
				</DialogHeader>
				<SwapCrewMembersForm open={open} onSubmit={handleSwapCrewMember} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};
