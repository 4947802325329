import { MonthYearCell } from "@/components/rotation-calendar/MonthYearCell";
import { getMonthNameFromDate } from "@/utils/calendarUtils";
import { addDays } from "date-fns";

type Props = {
	firstStartDate: Date;
	daysGenerated: number;
	weekType?: string;
	isActiveWeek?: boolean;
};

export const LabelCells = ({ firstStartDate, daysGenerated, weekType, isActiveWeek }: Props) => {
	const firstDayOfWeek = addDays(firstStartDate, daysGenerated);
	const lastDayOfWeek = addDays(firstStartDate, daysGenerated + 6);

	const firstDayOfWeekMonthName = getMonthNameFromDate(firstDayOfWeek);
	const lastDayOfWeekMonthName = getMonthNameFromDate(lastDayOfWeek);
	const firstDayOfWeekYear = firstDayOfWeek.getFullYear();
	const lastDayOfWeekYear = lastDayOfWeek.getFullYear();

	return (
		<>
			<div className={`text-center flex items-center justify-center rounded-md border border-gray-300 col-span-2 text-xs lg:text-sm font-medium ${isActiveWeek ? "bg-orange-200" : ""}`}>
				{weekType}
			</div>
			<MonthYearCell
				months={[firstDayOfWeekMonthName, lastDayOfWeekMonthName]}
				years={[firstDayOfWeekYear, lastDayOfWeekYear]}
			/>
		</>
	);
};
