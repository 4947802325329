import { useState } from "react";

import { useGetVesselQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { useImportCrewMemberMutation } from "@/app/api/slices/crewMembersApiSlice";
import { ImportCrewMemberForm } from "@/components/forms/ImportCrewMemberForm";
import { type FormType } from "@/components/forms/ImportCrewMemberForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const ImportCrewMemberDialog = () => {
	const auth = useAuth();
	const vesselId = useCurrentVesselId();
	const role = auth?.user?.role?.name || "";

	const { data: vesselResponse, isLoading: isLoadingVessel } = useGetVesselQuery(
		{ vesselId },
		{ skip: !vesselId },
	);

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [importCrewMemberMutation, { isLoading }] = useImportCrewMemberMutation();

	const handleImportCrewMember = async (data: FormType) => {
		// vessel is not needed for the mutation
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { vessel, ...dataToSend } = data;

		await importCrewMemberMutation({ vesselId, data: dataToSend })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added crew member.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	if (isLoadingVessel || !["fleet_manager"].includes(role) || !vesselResponse?.data?.fleet) {
		return null;
	}

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button id="add-crew-member-button" className="uppercase">
					Import crew member
				</Button>
			</DialogTrigger>
			<DialogContent
				id="add-crew-member-dialog"
				className="sm:max-w-[500px]"
				onInteractOutside={(e) => {
					e.preventDefault();
				}}
			>
				<DialogHeader>
					<DialogTitle>Import Crew Member</DialogTitle>
				</DialogHeader>
				<ImportCrewMemberForm onSubmit={handleImportCrewMember} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};
