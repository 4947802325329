import { useGetFleetsQuery } from "@/app/api/slices/admin/adminFleetApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { FleetsTable } from "@/components/tables/FleetsTable";
import { WhiteBox } from "@/components/WhiteBox";

export const FleetsListingPage = () => {
	const { data: response, isLoading, isFetching } = useGetFleetsQuery({});

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<WhiteBox>
			<FleetsTable data={response?.data || []} />
		</WhiteBox>
	);
};
