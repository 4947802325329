import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { MultipleSelector, type Option } from "@/components/multi-select";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { type FormProps } from "@/types/Form";

import { ButtonLoader } from "@/components/ButtonLoader";

import { useGetVesselsQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { Skeleton } from "@/components/ui/skeleton";
import { type Vessel } from "@/types/Vessel";
import { formSchema, type FormType } from "./formSchema";

type Props = FormProps<FormType> & {
	fleetId?: number;
};

export const FleetUpsertForm = ({
	onSubmit,
	isLoading,
	defaultValues = {
		name: "",
		vessels: [],
	},
	fleetId = 0,
}: Props) => {
	const {
		data: vesselsResponse,
		isLoading: isLoadingVessels,
		// isFetching: isFetchingVessels,
	} = useGetVesselsQuery({});

	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const { handleSubmit, control } = form;

	const options = (vesselsResponse?.data?.map((vessel: Vessel) => ({
		label: vessel.name,
		value: String(vessel.id),
		disable: vessel.fleet !== null && vessel.fleet.id !== fleetId,
	})) || []) as Option[];

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Fleet name</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="vessels"
							render={({ field }) => {
								return (
									<FormItem>
										<div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-2">
											<div className="relative top-2.5 flex items-center justify-start gap-2">
												<FormLabel className="whitespace-nowrap">Vessels</FormLabel>
												<FormMessage />
											</div>
											{!isLoadingVessels && (
												<FormControl>
													<MultipleSelector
														{...field}
														defaultOptions={options}
														placeholder="Select vessels"
														emptyIndicator={
															<p className="text-center text-xs leading-10 text-gray-600 dark:text-gray-400">
																no options found
															</p>
														}
													/>
												</FormControl>
											)}
											{isLoadingVessels && <Skeleton className="h-10 w-full" />}
										</div>
									</FormItem>
								);
							}}
						/>
					</div>
					<div className="flex justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};
