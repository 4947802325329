import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CheckIcon } from "lucide-react";
import { format as formatFns } from "date-fns";

import { ButtonLoader } from "@/components/ButtonLoader";
import * as Checkbox from '@radix-ui/react-checkbox';
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogClose,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { CalendarPicker } from "../ui/calendar-picker";
import { 
	useEditContractEndDateMutation
} from "@/app/api/slices/rotationCalendarApiSlice";
import { type RotationCalendarData, type EditContractEndDateArgs } from "@/types/RotationCalendarInterfaces";
import { useToast } from "@/components/ui/use-toast";

import { handleServerErrors } from "@/utils/handleServerErrors";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

interface ChangeEndDateDialogProps {
	isExtendModalOpen: boolean;
	setIsExtendModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedContractId: string | number | null | undefined;
	selectedContract: RotationCalendarData | undefined;
}

export const ChangeEndDateDialog = ({
	isExtendModalOpen,
	setIsExtendModalOpen,
	selectedContractId,
	selectedContract
}: ChangeEndDateDialogProps) => {
	const { format } = useDateFormatter();
	const vessel_id = useCurrentVesselId();
	const { user_id } = useParams();

	const [isAutomaticallyGenerated, setIsAutomaticallyGenerated] = useState(false);
	const [extendedEndDate, setExtendedEndDate] = useState<Date>();

	const { toast } = useToast();
	const [editContractEndDate, 
		{ isLoading: isEditingEndDate }
	] = useEditContractEndDateMutation();

	const editContractEndDateHandler = async ({vessel_id, user_id, data} : EditContractEndDateArgs) => {
		await editContractEndDate({
			vessel_id,
			user_id,
			data
		}).unwrap()
		.then((_payload) => {
			setIsExtendModalOpen(false);

			toast({
				variant: "success",
				title: "Successfully updated end date.",
			});
		})
		.catch((error) => handleServerErrors(error, toast));
	}

	const saveExtendDateHandler = () => {
		if(!vessel_id || !user_id || !selectedContractId || !extendedEndDate) return;
		const data = {
			id: selectedContractId,
			end_date: extendedEndDate ? formatFns(extendedEndDate, "y-MM-dd") : "",
			isAutomaticallyGenerated: isAutomaticallyGenerated
		}
		editContractEndDateHandler({vessel_id: vessel_id, user_id: user_id, data});
	}

	const isAutomaticallyGeneratedHandler = (val: boolean) => {
		setIsAutomaticallyGenerated(val);
	}

	useEffect(() => {
		if(selectedContract?.end_date) {
			setExtendedEndDate(selectedContract.end_date ? new Date(selectedContract.end_date) : undefined);
		}
	}, [selectedContract])

	return (
		<Dialog open={isExtendModalOpen} onOpenChange={setIsExtendModalOpen}>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Generate rotations until date</DialogTitle>
				</DialogHeader>

				<div>
					<p className="text-sm mb-2">
						<span>Rotations generated until: </span>
						<span>{(selectedContract && selectedContract.end_date) ? format(selectedContract.end_date) : ""}</span>
					</p>
					<div className="flex items-center">
						<p className="text-sm">
						If the selected date is greater than the above date, additional rotations will be added. If the selected date is smaller than the above date, rotations beyond this date will be deleted.
						</p>
					</div>
					<div className="mt-3 flex justify-between ">
						<Label className="whitespace-nowrap me-3">Generate rotations until</Label>
						<div className="w-auto">
							<CalendarPicker 
								placeholder="Select end date"
								disabled={isEditingEndDate} 
								onSelect={setExtendedEndDate} 
								selected={extendedEndDate}
								defaultMonth={selectedContract?.generated_until ? new Date(selectedContract.generated_until) : new Date()}
							/>
						</div>
					</div>
					<div className="mt-2 flex justify-between" >
						<label className="text-sm">Generate rotations automatically</label>
						<div>
							<Checkbox.Root className="w-10 h-10 flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
								onCheckedChange={isAutomaticallyGeneratedHandler}
								checked={isAutomaticallyGenerated}
								disabled={isEditingEndDate}
							>
								<Checkbox.Indicator className="CheckboxIndicator">
									<CheckIcon />
								</Checkbox.Indicator>
							</Checkbox.Root>
						</div>
					</div>
					
					
				</div>

				<div className="flex justify-between">
					
					<DialogClose asChild>
						<Button type="button" variant="secondary" disabled={isEditingEndDate}>
							Cancel
						</Button>
					</DialogClose>
					<Button onClick={ saveExtendDateHandler } disabled={isEditingEndDate}>
						<ButtonLoader isLoading={isEditingEndDate}/>
						Save
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};
