import { cn } from "@/lib/utils";
import { X } from "lucide-react";
import { createContext, useContext, useMemo, useState, type ReactNode } from "react";

type NotificationType = "success" | "error" | "info";

type Notification = {
	id: string;
	message: string | ReactNode;
	type: NotificationType;
	open: boolean;
};

type NotificationBarContextType = {
	setNotificationBarMessage: ({
		id,
		message,
		type,
	}: {
		id: string;
		message: string | ReactNode;
		type: NotificationType;
	}) => string;
	removeNotificationBarMessage: (id: string) => void;
	BarNotifications: ReactNode[];
};

const NotificationBarContext = createContext<NotificationBarContextType | undefined>(undefined);

export const NotificationBarProvider = ({ children }: { children: ReactNode }) => {
	const [notifications, setNotifications] = useState<Notification[]>([]);

	const setMessage = ({
		id,
		message,
		type,
	}: {
		id: string;
		message: string | ReactNode;
		type: NotificationType;
	}) => {
		const newNotification = { id, message, type, open: true };

		setNotifications((prevNotifications) => {
			return [...prevNotifications, newNotification];
		});

		return id;
	};

	const removeNotification = (id: string) => {
		setNotifications((prevNotifications) =>
			prevNotifications.filter((notification) => notification.id !== id),
		);
	};

	const BarNotifications = useMemo(() => {
		return notifications.map((notification) => {
			if (!notification.open) return null;

			let classes = "";

			switch (notification.type) {
				case "success":
					classes = "bg-green-500";
					break;
				case "error":
					classes = "bg-red-500";
					break;
				case "info":
					classes = "bg-blue-500";
					break;
				default:
					break;
			}

			return (
				<div
					key={notification.id}
					className={cn("flex items-center justify-between px-4 py-2 text-white", classes)}
				>
					<div>{notification.message}</div>
					<button onClick={() => removeNotification(notification.id)}>
						<X className="h-4 w-4" />
					</button>
				</div>
			);
		});
	}, [notifications]);

	const value = useMemo(
		() => ({
			BarNotifications,
			setNotificationBarMessage: setMessage,
			removeNotificationBarMessage: removeNotification,
		}),
		[BarNotifications],
	);

	return (
		<NotificationBarContext.Provider value={value}>{children}</NotificationBarContext.Provider>
	);
};

export const useNotificationBar = () => {
	const context = useContext(NotificationBarContext);
	if (!context) {
		throw new Error("useNotificationBar must be used within a NotificationBarProvider");
	}
	return context;
};
