import { apiSlice } from "@/app/api/apiSlice";

export const vesselsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVessel: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}`,
			providesTags: (_result, _error, { vesselId }) => [{ type: "Vessels", id: vesselId }],
		}),
		getVessels: builder.query({
			query: () => `/api/vessels`,
			providesTags: ["Vessels"],
		}),
		addVessel: builder.mutation({
			query: ({ data }) => ({
				url: `/api/vessels`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Vessels", "Fleets", "FleetVessels"],
		}),
		updateVessel: builder.mutation({
			query: ({ data, vesselId }) => ({
				url: `/api/vessels/${vesselId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["Vessels", "Fleets", "FleetVessels"],
		}),
		removeVessel: builder.mutation({
			query: ({ vesselId }) => ({
				url: `/api/vessels/${vesselId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Vessels", "Fleets", "FleetVessels"],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetVesselQuery,
	useGetVesselsQuery,
	useAddVesselMutation,
	useUpdateVesselMutation,
	useRemoveVesselMutation,
} = vesselsApiSlice;
