import { createSlice } from "@reduxjs/toolkit";

import { appConfigApiSlice } from "@/app/api/slices/appConfigApiSlice";
import { type RootState } from "@/app/store";

const DEFAULT_DATE_FORMAT = "dd-MM-yyyy";

type BrandingData = {
	meta: string;
	domain: string;
	logoUrl: string;
	backgroundColor: string;
	topBarColor: string;
	buttonColor: string;
};

type ConfigState = {
	rotationTypes: string[] | null;
	brandingData: BrandingData | null;
	preferredDateFormat: string;
};

const initialState: ConfigState = {
	rotationTypes: null,
	brandingData: null,
	preferredDateFormat: DEFAULT_DATE_FORMAT,
};

const slice = createSlice({
	name: "config",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			appConfigApiSlice.endpoints.getAppConfig.matchFulfilled,
			(state, { payload }) => {
				state.rotationTypes = payload.data.rotationTypes;
				state.brandingData = payload.data.brandingData;
			},
		);
	},
});

export default slice.reducer;

// Selectors
export const selectConfig = (state: RootState) => state.config;

export const selectPreferredDateFormat = (state: RootState) =>
	selectConfig(state).preferredDateFormat;
