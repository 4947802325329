import { useState } from "react";

import { useAddAdminSectionMutation } from "@/app/api/slices/admin/adminKnowledgeBaseApiSlice";
import { useAddVesselSectionMutation } from "@/app/api/slices/manager/managerKnowledgeBaseApiSlice";
import { KnowledgeBaseSectionUpsertForm } from "@/components/forms/KnowledgeBaseSectionUpsertForm";
import { type FormType } from "@/components/forms/KnowledgeBaseSectionUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const AddKnowledgeBaseSectionDialog = () => {
	const auth = useAuth();
	const vesselId = useCurrentVesselId();
	const role = auth?.user?.role.name || "";
	const { toast } = useToast();
	const [open, setOpen] = useState(false);

	const [addAdminSectionMutation, { isLoading: isAddingAdminSection }] =
		useAddAdminSectionMutation();
	const [addVesselSectionMutation, { isLoading: isAddingVesselSection }] =
		useAddVesselSectionMutation();

	const handleAddSection = async ({ name }: FormType) => {
		if (["manager", "fleet_manager"].includes(role)) {
			await addVesselSectionMutation({ vesselId, name })
				.unwrap()
				.then((_payload) => {
					setOpen(false);
					toast({
						variant: "success",
						title: "Successfully added section.",
					});
				})
				.catch((error) => handleServerErrors(error, toast));
			return;
		}

		await addAdminSectionMutation({ name })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added section.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button className="mb-4 w-full uppercase">Add section</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Add Section</DialogTitle>
				</DialogHeader>
				<KnowledgeBaseSectionUpsertForm
					onSubmit={handleAddSection}
					isLoading={isAddingAdminSection || isAddingVesselSection}
				/>
			</DialogContent>
		</Dialog>
	);
};
