import { apiSlice } from "@/app/api/apiSlice";

export const fleetApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getFleetVessels: builder.query({
			query: () => `/api/fleets/vessels`,
			providesTags: ["FleetVessels"],
		}),
		changeActiveVessel: builder.mutation({
			query: ({ id }) => ({
				url: `/api/fleets/active-vessel`,
				method: "POST",
				body: { id },
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"AdminKnowledgeBaseSections",
							"AdminKnowledgeBaseArticles",
							"Vessels",
							"ManagerSuggestions",
							"MyVesselInfo",
							"FleetUsers",
							"Vessels",
						],
		}),
		getFleetUsers: builder.query({
			query: ({ exclude = "" }) =>
				`/api/fleets/users` + (exclude ? `?excludeVessel=${exclude}` : ""),
			providesTags: ["FleetUsers"],
		}),
	}),
	overrideExisting: true,
});

export const { useGetFleetVesselsQuery, useChangeActiveVesselMutation, useGetFleetUsersQuery } =
	fleetApiSlice;
