import { useEffect, useState } from "react";

import {
	useGetStripePaymentUrlQuery,
	useGetStripePortalUrlQuery,
	useGetSubscriptionDetailsQuery,
} from "@/app/api/slices/manager/paymentsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { useToast } from "@/components/ui/use-toast";
import { WhiteBox } from "@/components/WhiteBox";
import { useAuth } from "@/hooks/useAuth";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { AlertTriangle } from "lucide-react";

export const PaymentsPage = () => {
	const auth = useAuth();
	const { toast } = useToast();
	const vesselId = useCurrentVesselId();
	const isDemoAccount = auth?.user?.isFromDemoAccount;

	const [isPaymentDisabled, setIsPaymentDisabled] = useState(false);

	const { data: response, isLoading } = useGetSubscriptionDetailsQuery({ vesselId });

	const isActive = response?.data?.isActive;

	const {
		data: stripePortalResponse,
		isLoading: isLoadingStripePortal,
		error: portalError,
	} = useGetStripePortalUrlQuery(
		{ vesselId },
		{ skip: isActive === undefined || isActive === 0 || isDemoAccount },
	);
	const {
		data: stripePaymentResponse,
		isLoading: isLoadingStripePayment,
		error: paymentError,
	} = useGetStripePaymentUrlQuery(
		{ vesselId },
		{ skip: isActive === undefined || isActive === 1 || isDemoAccount },
	);

	useEffect(() => {
		if (portalError) {
			handleServerErrors(portalError, toast);
		}
		if (paymentError) {
			handleServerErrors(paymentError, toast);
		}
	}, [portalError, paymentError, toast]);

	useEffect(() => {
		if (stripePortalResponse) {
			if (!stripePortalResponse.url && !stripePortalResponse.payment) {
				setIsPaymentDisabled(true);
				return;
			}
			window.location.href = stripePortalResponse.url;
		}

		if (stripePaymentResponse) {
			if (!stripePaymentResponse.url && !stripePaymentResponse.payment) {
				setIsPaymentDisabled(true);
				return;
			}
			window.location.href = stripePaymentResponse.url;
		}
	}, [stripePortalResponse, stripePaymentResponse]);

	if (isLoading || isLoadingStripePortal || isLoadingStripePayment) return <InLayoutLoader />;

	if (portalError || paymentError) {
		return (
			<WhiteBox>
				<div className="inline-block rounded-md bg-red-500 p-4 text-white">
					<p className="flex items-center font-semibold">
						<AlertTriangle className="mr-2 h-6 w-6" />
						Something went wrong. Please try again later or contact administrator.
					</p>
				</div>
			</WhiteBox>
		);
	}

	if (isDemoAccount) {
		return (
			<WhiteBox>
				<h1 className="text-xl font-semibold md:text-2xl">Payments</h1>
				<div className="mt-4 inline-block rounded-md bg-yellow-500 p-4 text-white">
					<p className="flex items-center font-semibold">
						<AlertTriangle className="mr-2 h-6 w-6" />
						You are using a demo account. Payments are disabled.
					</p>
				</div>
			</WhiteBox>
		);
	}

	if (isPaymentDisabled) {
		return (
			<WhiteBox>
				<h1 className="text-xl font-semibold md:text-2xl">Payments</h1>
				<div className="mt-4 inline-block rounded-md bg-yellow-500 p-4 text-white">
					<p className="flex items-center font-semibold">
						<AlertTriangle className="mr-2 h-6 w-6" />
						Payments are disabled for your account.
					</p>
				</div>
			</WhiteBox>
		);
	}

	return (
		<WhiteBox>
			<h1 className="text-xl font-semibold md:text-2xl">Payments</h1>
			<p className="mt-2">Redirecting to Stripe portal...</p>
		</WhiteBox>
	);
};
