import { Check } from "lucide-react";
import { useState } from "react";

import { useUpdateUserRequestMutation } from "@/app/api/slices/manager/notificationsApiSlice";
import { RequestUpdateForm } from "@/components/forms/RequestUpdateForm";
import { type FormType } from "@/components/forms/RequestUpdateForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";
import { type UserRequest } from "@/types/UserRequest";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	request: UserRequest;
};

export const RequestAcceptDialog = ({ request }: Props) => {
	const vesselId = useCurrentVesselId();

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateUserRequestMutation, { isLoading }] = useUpdateUserRequestMutation();

	const handleAcceptRequest = async ({ note }: FormType) => {
		await updateUserRequestMutation({
			vesselId,
			requestId: request.id,
			data: { ...request, note: note || "", status: "accepted" },
		})
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully accepted request.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#4CAF50" }}>
					<Check color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Accept Request</DialogTitle>
				</DialogHeader>
				<RequestUpdateForm onSubmit={handleAcceptRequest} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};
