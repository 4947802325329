import { Ship } from "lucide-react";

import { useGetVesselQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { WhiteBox } from "@/components/WhiteBox";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

export const VesselInfoBar = () => {
	const vesselId = useCurrentVesselId();

	const {
		data: response,
		isLoading,
		isFetching,
	} = useGetVesselQuery({ vesselId }, { skip: !vesselId });

	return (
		<WhiteBox className="mb-4 flex items-center gap-4 py-2">
			<div className="flex h-8 w-8 items-center">
				<Ship />
			</div>
			{isLoading || isFetching ? (
				<div className="flex flex-wrap items-center gap-x-4 gap-y-1 sm:flex-nowrap">
					<Skeleton className="h-4 w-[180px]" />
					<Skeleton className="h-4 w-[180px]" />
				</div>
			) : (
				<div className="flex flex-wrap items-center gap-x-4 gap-y-1 text-sm sm:flex-nowrap sm:text-base">
					<div>
						<span className="font-semibold">Vessel Name:</span> {response?.data?.name || "-"}
					</div>
					<div>
						<span className="font-semibold">Vessel IMO:</span> {response?.data?.imo || "-"}
					</div>
				</div>
			)}
		</WhiteBox>
	);
};
