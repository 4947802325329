import * as z from "zod";

import { vesselImoZodRule, vesselNameZodRule } from "@/lib/zod";

export const formSchema = z.object({
	name: vesselNameZodRule,
	imo: vesselImoZodRule,
	leave_balance_type: z.coerce.number().min(1, { message: "Leave Balance Type is required" }),
	fleet: z.coerce.number().optional(),
});

export type FormType = z.infer<typeof formSchema>;
