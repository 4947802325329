import { type ColumnDef } from "@tanstack/react-table";

import { MagicLinkExtendValidityDialog } from "@/components/dialogs/MagicLinkExtendValidityDialog";
import { MagicLinkRemoveDialog } from "@/components/dialogs/MagicLinkRemoveDialog";
import { MagicLinkReseedDialog } from "@/components/dialogs/MagicLinkReseedDialog";
import { MagicLinkTurnOffDialog } from "@/components/dialogs/MagicLinkTurnOffDialog";
import { CopyButton } from "@/components/tables/MagicLinksTable/CopyButton";
import { type FormatType } from "@/hooks/useDateFormatter";
import { type MagicLink } from "@/types/MagicLink";

export const getColumns = (format: FormatType): ColumnDef<MagicLink>[] => {
	return [
		{
			accessorKey: "name",
			header: "Client",
			cell: ({ row }) => {
				return <div>{row.getValue("name")}</div>;
			},
		},
		{
			accessorKey: "email",
			header: "Email",
			cell: ({ row }) => {
				return (
					<div>
						<a href={`mailto:${row.getValue("email")}`}>{row.getValue("email")}</a>
					</div>
				);
			},
		},
		{
			accessorKey: "validUntil",
			header: "Valid until",
			cell: ({ row }) => (
				<div>{row.getValue("validUntil") ? format(row.getValue("validUntil")) : "expired"}</div>
			),
		},
		{
			accessorKey: "link",
			header: "Link",
			cell: ({ row }) => {
				const link: string = row.getValue("link");
				if (!link) return "expired";

				return (
					<>
						<div className="flex items-center gap-2">
							{link}
							<CopyButton link={link} />
						</div>
					</>
				);
			},
		},
		{
			id: "actions",
			header: () => <div>Actions</div>,
			cell: ({ row }) => {
				const magicLinkId = row.original.id;

				return (
					<div className="flex justify-center gap-2">
						<MagicLinkReseedDialog magicLinkId={magicLinkId} />
						<MagicLinkExtendValidityDialog magicLinkId={magicLinkId} />
						<MagicLinkTurnOffDialog magicLinkId={magicLinkId} />
						<MagicLinkRemoveDialog magicLinkId={magicLinkId} />
					</div>
				);
			},
		},
	];
};
