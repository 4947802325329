import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetCrewMemberDocumentsQuery } from "@/app/api/slices/documentsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { UserInfoBar } from "@/components/UserInfoBar";
import { WhiteBox } from "@/components/WhiteBox";
import { CrewMemberDocumentsAccordion } from "@/components/accordions/CrewMemberDocumentsAccordion";
import { AddCrewMemberDocumentsDialog } from "@/components/dialogs/AddCrewMemberDocumentsDialog";
import { useJoyride } from "@/contexts/JoyrideContext";
import { joyride_redirect_4_index } from "@/hocs/JoyrideWrapper";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

export const CrewMemberDocumentsPage = () => {
	const vesselId = useCurrentVesselId();
	const { userId } = useParams();

	const {
		data: response,
		isLoading,
		isFetching,
	} = useGetCrewMemberDocumentsQuery({ vesselId, userId });

	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		if (!run && !isLoading && stepIndex === joyride_redirect_4_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [isLoading]);

	if (isLoading || isFetching)
		return (
			<div id="crew-member-documents-page">
				<InLayoutLoader />
			</div>
		);

	return (
		<div id="crew-member-documents-page">
			<UserInfoBar />
			<WhiteBox className="mt-4">
				<h1 className="mb-4 text-xl font-semibold md:text-2xl">Documents</h1>
				<div className="mb-4">
					<AddCrewMemberDocumentsDialog />
				</div>
				<CrewMemberDocumentsAccordion documents={response?.data || []} />
			</WhiteBox>
		</div>
	);
};
