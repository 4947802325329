import { type ColumnDef } from "@tanstack/react-table";

import { EditManagerDialog } from "@/components/dialogs/EditManagerDialog";
import { RemoveManagerDialog } from "@/components/dialogs/RemoveManagerDialog";
import { type User } from "@/types/User";

export const columns: ColumnDef<User>[] = [
	{
		accessorKey: "name",
		accessorFn: (row) => `${row.firstName} ${row.lastName}`,
		header: "Name",
		cell: ({ row }) => {
			const firstName = row.original.firstName;
			const lastName = row.original.lastName;

			return <div className="capitalize">{`${firstName} ${lastName}`}</div>;
		},
	},
	{
		accessorKey: "role",
		header: "Role",
		cell: ({ row }) => {
			const role = row.getValue("role") as { id: number; name: string };

			if (!role?.name) return null;

			return <div>{role.name === "fleet_manager" ? "Fleet Manager" : "Manager"}</div>;
		},
	},
	{
		accessorKey: "phone",
		header: "Phone",
		cell: ({ row }) => <div>{row.getValue("phone")}</div>,
	},
	{
		accessorKey: "email",
		header: "Email",
		cell: ({ row }) => <div>{row.getValue("email")}</div>,
	},
	{
		id: "actions",
		header: () => <div>Actions</div>,
		cell: ({ row }) => {
			const managerId = row.original.id;
			const isDemoAccount = row.original?.isDemoAccount;

			return (
				<div className="flex justify-center gap-2">
					<EditManagerDialog managerId={managerId} manager={{ ...row.original }} />
					<RemoveManagerDialog managerId={managerId} disabled={isDemoAccount} />
				</div>
			);
		},
	},
];
